import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { CertificateTableItemModel } from '@app/modules/certificate/models/certificate-table.model';
import { TableDataModel } from '@app/shared/models';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CertificateService {
    private readonly endpoints = {
        overview: 'Certificate/GetOverview',
        delete: 'Certificate/delete?Id=:id'
    };

    constructor(private readonly httpService: HttpService) {}

    getOverview(
        sorting: TableSortingModel,
        pagination: TablePaginationModel,
        filter?: TableFilterModel[],
        activityId?: number,
        relationId?: number
    ): Observable<TableDataModel<CertificateTableItemModel>> {
        const sortingFilter = filter ? `${parse(filter, 'Filters')}` : '';
        const paginationParams = parse(pagination, 'pagination');
        const sortingParams = parse(sorting, 'sorting');

        const activityIdParam = activityId ? `activityId=${activityId}` : null;
        const relationIdParam = relationId ? `relationId=${relationId}` : null;

        let params = `${sortingFilter}&${paginationParams}&${sortingParams}`;

        if (activityIdParam) {
            params += `&${activityIdParam}`;
        }
        if (relationIdParam) {
            params += `&${relationIdParam}`;
        }

        return this.httpService.get<TableDataModel<CertificateTableItemModel>>(
            this.endpoints.overview + `?${params}`,
        );
    }

    delete(id: number): Observable<void> {
        return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
    }
}
