import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as CertificateOverview from './certificate-overview.reducer'

export const certificateFeatureKey = 'certificate'

export interface CertificateState {
    certificateOverview: CertificateOverview.State
}

export interface State {
    certificate: CertificateState
}

const map: ActionReducerMap<CertificateState, Action> = {
    certificateOverview: CertificateOverview.reducer
}

export const reducers = (state: CertificateState, action: Action) => combineReducers(map)(state, action);

const selectCertificateState = createFeatureSelector<CertificateState>(certificateFeatureKey);

export const selectState = createSelector(selectCertificateState, state => state)
export const selectCertificateOverviewState = createSelector(selectState, state => state.certificateOverview)
export const selectCertificateOverview = createSelector(selectState, state => state.certificateOverview.certificates)

export const selectCertificateOverviewList = createSelector(selectCertificateOverviewState, CertificateOverview.getList)
export const selectCertificateOverviewPagination = createSelector(selectCertificateOverviewState, CertificateOverview.getPagination)
export const selectCertificateOverviewFilter = createSelector(selectCertificateOverviewState, CertificateOverview.getFilter)
export const selectRelationId = createSelector(selectCertificateOverviewState, state => state.relationId);
export const selectActivityId = createSelector(selectCertificateOverviewState, state => state.activityId);

export const selectCertificateOverviewSorting = createSelector(selectCertificateOverviewState, CertificateOverview.getSorting)
