import { NgModule } from '@angular/core';

import { reducers, certificateFeatureKey } from '@app/modules/certificate/reducers';
import { CertificateRoutingModule } from '@app/modules/certificate/certificate-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CertificateOverviewComponent } from '@app/modules/certificate/components/certificate-overview/certificate-overview.component';
import { CertificateEffects } from '@app/modules/certificate/effects/certificate.effects';

const COMPONENTS = [CertificateOverviewComponent];

const IMPORTS = [
    SharedModule,
    CertificateRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(certificateFeatureKey, reducers),
    EffectsModule.forFeature([CertificateEffects]),
];

@NgModule({
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})
export class CertificateModule {}
