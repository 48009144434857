import { NgModule } from "@angular/core";
import { ActivityDownloadOverviewComponent } from "@app/modules/activity-download/components/activity-download-overview/activity-download-overview.component";
import { ActivityDownloadUploadModalComponent } from "@app/modules/activity-download/components/activity-download-upload-modal/activity-download-upload-modal.component";

import { ActivityDownloadEffects } from "@app/modules/activity-download/effects/activity-download.effects";
import { activityDownloadFeatureKey, reducers } from "@app/modules/activity-download/reducers";
import { ActivityRoutingModule } from "@app/modules/activity/activity-routing.module";
import { SharedModule } from "@app/shared/shared.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzUploadModule } from "ng-zorro-antd/upload";

const COMPONENTS = [
    ActivityDownloadOverviewComponent,
    ActivityDownloadUploadModalComponent
];

const IMPORTS = [
    SharedModule,
    ActivityRoutingModule,
    NzUploadModule,
    NzDividerModule,
    StoreModule.forFeature(activityDownloadFeatureKey, reducers),
    EffectsModule.forFeature([ActivityDownloadEffects])
];

@NgModule({
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class ActivityDownloadModule { }
