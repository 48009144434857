import * as ActivityActions from '@app/modules/activity/actions/activity-api.actions';
import { ActivityUpdateModel } from '@app/modules/activity/models';
import { ActivityTemplateCreateModel } from '@app/modules/activity-template/models';
import { ActivityTypeCodeModel } from '@app/modules/activity-type/models/activity-type-code.model';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
    activity: {} as ActivityUpdateModel,
    activityTemplate: null,
    loading: false,
    errors: {},
    activityTypes: null,
    activityType: -1,
    activityLocations: null,
    code: null,
    relations: [],
};

export interface State {
    activity: ActivityUpdateModel;
    activityTemplate: ActivityTemplateCreateModel | null;
    loading: boolean;
    errors: { [key: string]: string[] };
    activityTypes: ActivityTypeCodeModel[] | null;
    activityType: number;
    activityLocations: KeyValueModel[] | null;
    code: string | null;
    relations: KeyValueModel[];
}

export const reducer = createReducer(
    initialState,
    on(ActivityActions.Clear, () => ({ ...initialState })),

    on(ActivityActions.Get, (state) => ({ ...state, loading: true })),
    on(ActivityActions.GetSuccess, (state, activity) => ({
        ...state,
        activity,
        loading: false,
    })),
    on(ActivityActions.GetError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),
    on(ActivityActions.CreateSuccess, (state, activityTemplate) => ({
        ...state,
        activityTemplate,
        loading: false,
    })),
    on(ActivityActions.GetTemplateSuccess, (state, activityTemplate) => ({
        ...state,
        activityTemplate,
        loading: false,
    })),

    on(ActivityActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityActions.UpdateSuccess, (state, activity) => ({
        ...state,
        activity,
        loading: false,
    })),
    on(ActivityActions.UpdateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(ActivityActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityActions.CreateSuccess, (state, activity) => ({
        ...state,
        activity,
        loading: false,
    })),
    on(ActivityActions.CreateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(ActivityActions.GetActivityTypes, (state) => ({ ...state, loading: true })),
    on(ActivityActions.GetActivityTypesSuccess, (state, { activityTypes }) => ({
        ...state,
        activityTypes,
        loading: false,
    })),
    on(ActivityActions.GetActivityTypesError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(ActivityActions.GetActivityLocations, (state) => ({ ...state, loading: true })),
    on(ActivityActions.GetActivityLocationsSuccess, (state, { activityLocations }) => ({
        ...state,
        activityLocations,
        loading: false,
    })),
    on(ActivityActions.GetActivityLocationsError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(ActivityActions.CheckCode, (state) => ({ ...state, loading: true })),
    on(ActivityActions.CheckCodeSuccess, (state, { code }) => ({ ...state, code, loading: false })),
    on(ActivityActions.CheckCodeError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(ActivityActions.EmptyRelations, (state) => ({...state, relations:[]})),
    on(ActivityActions.GetRelations, (state) => ({ ...state, loading: true })),
    on(ActivityActions.GetRelationsSuccess, (state, relations) => ({ ...state, relations:relations?.relations})),
    on(ActivityActions.GetRelationsError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getActivity = (state: State) => state.activity;
export const getActivityTemplate = (state: State) => state.activityTemplate;
export const getActivityTypes = (state: State) => state.activityTypes;
export const getChosenActivityType = (state: State) => state.activityType;
export const getActivityLocations = (state: State) => state.activityLocations;
export const getCode = (state: State) => state.code;
export const getRelations = (state: State) => state.relations;
