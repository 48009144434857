import { NgModule } from '@angular/core';
import { BackgroundEffects } from '@app/modules/background/effects/background.effects';
import { backgroundFeatureKey, reducers } from '@app/modules/background/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const IMPORTS = [
	SharedModule,
	NzDividerModule,
	StoreModule.forFeature(backgroundFeatureKey, reducers),
	EffectsModule.forFeature([BackgroundEffects]),
];

@NgModule({
	imports: [IMPORTS],
	providers: [],
})
export class BackgroundModule {}
