import { CertificateTableItemModel } from '@app/modules/certificate/models/certificate-table.model';
import * as CertificateApiActions from '@app/modules/certificate/actions/certificate-api.actions';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
    certificates: new TableOverviewModel<CertificateTableItemModel>('GeneratedOn', -1),
    relationId: null,
    activityId: null,
    loading: false,
    errors: {},
};

export interface State {
    certificates: TableStateModel<CertificateTableItemModel>;
    loading: boolean;
    relationId: number;
    activityId: number;
    errors: { [key: string]: string[] };
}

export const reducer = createReducer(
    initialState,

    // normal overview
    on(CertificateApiActions.GetOverview, (state) => ({
        ...state,
        certificates: { ...state.certificates, loading: true },
    })),
    on(CertificateApiActions.GetOverviewSuccess, (state, response) => ({
        ...state,
        certificates: {
            ...state.certificates,
            ...response,
            loading: false,
        },
    })),
    on(CertificateApiActions.GetOverviewError, (state) => ({
        ...state,
        certificates: { ...state.certificates, loading: false },
    })),

    on(CertificateApiActions.FilterOverview, (state, action) => ({
        ...state,
        activityId: action.activityId,
        relationId: action.relationId,
        certificates: { 
            ...state.certificates, 
            filter: action.filter, 
            pagination: action.pagination, 
            sorting: action.sorting
        }
    })),

    on(CertificateApiActions.Delete, (state) => ({ ...state, certificates: { ...state.certificates, loading: true } })),
    on(CertificateApiActions.DeleteSuccess, (state) => ({ ...state, certificates: { ...state.certificates, loading: false } })),
    on(CertificateApiActions.DeleteError, (state) => ({ ...state, certificates: { ...state.certificates, loading: false } })),
);

export const getLoading = (state: State) => state.certificates.loading;
export const getList = (state: State) => state.certificates.list;
export const getPagination = (state: State) => state.certificates.pagination;
export const getFilter = (state: State) => state.certificates.filter;
export const getSorting = (state: State) => state.certificates.sorting;
export const getSortingThen = (state: State) => state.certificates.thenBy;
export const getOverview = (state: State) => state.certificates;
export const getRelationId = (state: State) => state.relationId;
export const getActivityId = (state: State) => state.activityId;