import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { CertificateTableItemModel } from '@app/modules/certificate/models/certificate-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
    selector: 'app-certificate-overview',
    templateUrl: './certificate-overview.component.html'
})
export class CertificateOverviewComponent {
    @Input({ required: true }) data: TableStateModel<CertificateTableItemModel>;
    @Input() showRelationName = false;
    @Input() showActivityName = false;

    @Output() delete = new EventEmitter<{ id: number }>();

    destroyRef = inject(DestroyRef);

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
}
