import { CountryModel } from '@app/modules/countries/models';
import * as RelationApiActions from '@app/modules/relation/actions/relation-api.actions';
import { RelationUpdateModel } from '@app/modules/relation/models';
import { BillingInformationModel } from '@app/shared/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
    loading: false,
    errors: {},
    relation: {} as RelationUpdateModel,
    relationTypes: [],
    countries: [],
    relationBillingInformation: [],
    duplicatedRelation: {} as RelationUpdateModel
};

export interface State {
    loading: boolean;
    errors: { [key: string]: string[] };
    relation: RelationUpdateModel,
    relationTypes: KeyValueModel[],
    countries: CountryModel[],
    relationBillingInformation: BillingInformationModel[],
    duplicatedRelation: RelationUpdateModel
}

export const reducer = createReducer(
    initialState,
    on(RelationApiActions.Clear, () => ({ ...initialState })),

    on(RelationApiActions.Get, (state) => ({ ...state, loading: true })),
    on(RelationApiActions.GetSuccess, (state, relation) => ({
        ...state,
        relation,
        loading: false,
    })),
    on(RelationApiActions.GetError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),
    on(RelationApiActions.CreateSuccess, (state, relation) => ({
        ...state,
        relation,
        loading: false,
    })),
    on(RelationApiActions.CreateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(RelationApiActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(RelationApiActions.UpdateSuccess, (state, relation) => ({
        ...state,
        relation,
        loading: false,
    })),
    on(RelationApiActions.UpdateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(RelationApiActions.GetRelationTypes, (state) => ({ ...state, loading: true })),
    on(RelationApiActions.GetRelationTypesSuccess, (state, { relationTypes }) => ({
        ...state,
        relationTypes,
        loading: false,
    })),
    on(RelationApiActions.GetRelationTypesError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(RelationApiActions.GetRelationBillingInformation, (state) => ({ ...state, loading: true })),
    on(RelationApiActions.GetRelationBillingInformationSuccess, (state, { relationBillingInformation }) => ({
        ...state,
        relationBillingInformation,
        loading: false,
    })),
    on(RelationApiActions.GetRelationBillingInformationError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(RelationApiActions.GetCountries, (state) => ({ ...state, loading: true })),
    on(RelationApiActions.GetCountriesSuccess, (state, { countries }) => ({
        ...state,
        countries,
        loading: false,
    })),
    on(RelationApiActions.GetCountriesError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(RelationApiActions.GetByEmail, (state) => ({ ...state, loading: true })),
    on(RelationApiActions.GetByEmailSuccess, (state, duplicatedRelation) => ({
        ...state,
        duplicatedRelation,
        loading: false,
    })),
    on(RelationApiActions.GetByEmailError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    }))
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: State) => state.errors;

export const getRelation = (state: State) => state.relation;
export const getRelationTypes = (state: State) => state.relationTypes;
export const getCountries = (state: State) => state.countries;
export const getBillingInformation = (state: State) => state.relationBillingInformation;
export const getDuplicatedRelation = (state: State) => state.duplicatedRelation;
