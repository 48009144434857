import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { ActivityAvailableParticipantsLabels } from '@app/shared/enums/activity-available-participants';
import { ParticipantStatus, ParticipantStatusLabels } from '@app/modules/activity/enums/participant-status.enum';
import { PaymentStatus, PaymentStatusIcons } from '@app/modules/activity/enums/payment-status.enum';
import { ApplicationType } from '@app/shared/enums/application-type.enum';
import { RelationActivityTableItemModel } from '@app/modules/relation-activity/models/relation-activity-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
    selector: 'app-relation-activity-overview-table',
    templateUrl: './relation-activity-overview-table.component.html',
    styleUrls: ['./relation-activity-overview-table.component.less'],
})
export class RelationActivityTablePartComponent {
    @Input({ required: true }) data: RelationActivityTableItemModel[];
 
    @Output() create = new EventEmitter<void>();
    @Output() update = new EventEmitter<number>();

    @Output() getRelation = new EventEmitter<number>();
    @Output() resetOverview = new EventEmitter<void>();

    destroyRef = inject(DestroyRef);

    participantStatusMapping = ParticipantStatusLabels;
    participantActivityStatus = ParticipantStatus;

    paymentStatusMapping = PaymentStatusIcons;
    paymentActivityStatus = PaymentStatus;

    applicationType = ApplicationType;
    ActivityAvailableParticipantsMapping = ActivityAvailableParticipantsLabels;

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    draggingEnabled = false;
}
