import { NgModule } from '@angular/core';
import { RelationOverviewTableComponent } from '@app/modules/relation/components/overview/relation-overview-table.component';
import { RelationDetailFormComponent } from '@app/modules/relation/components/relation-detail-form/relation-detail-form.component';
import { RelationEffects } from '@app/modules/relation/effects/relation.effects';
import { RelationViewComponent } from '@app/modules/relation/pages/view/relation-view.component';
import { RelationViewInfoComponent } from '@app/modules/relation/components/relation-view-info/relation-view-info.component';
import { RelationDetailComponent } from '@app/modules/relation/pages/detail/relation-detail.component';
import { RelationOverviewComponent } from '@app/modules/relation/pages/overview/relation-overview.component';
import { reducers, relationFeatureKey } from '@app/modules/relation/reducers';
import { RelationRoutingModule } from '@app/modules/relation/relation-routing.module';
import { BillingInformationFormComponent, PhoneNumberInputComponent } from '@app/shared/components';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { RelationViewCardComponent } from '@app/modules/relation/components/relation-view-card/relation-view-card.component';
import { ToolboxSidebarComponent } from '@app/modules/relation/components/toolbox-sidebar/toolbox-sidebar.component';
import { NoteModule } from '@app/modules/note/note.module';
import { RelationActivityModule } from '@app/modules/relation-activity/relation-activity.module';
import { BackgroundModule } from '@app/modules/background/background.module';
import { CertificateModule } from '@app/modules/certificate/certificate.module';

const COMPONENTS = [
    RelationOverviewComponent, 
    RelationOverviewTableComponent, 
    RelationViewComponent,
    RelationViewInfoComponent,
    RelationDetailComponent, 
    RelationDetailFormComponent,
    RelationViewCardComponent,
    PhoneNumberInputComponent,
    ToolboxSidebarComponent,
    BillingInformationFormComponent
];

const IMPORTS = [
    SharedModule,
    RelationRoutingModule,
    RelationActivityModule,
    CertificateModule,
    BackgroundModule,
    NoteModule,
    NzDividerModule,
    StoreModule.forFeature(relationFeatureKey, reducers),
    EffectsModule.forFeature([RelationEffects]),
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})
export class RelationModule {}
