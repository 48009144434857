import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as RelationActivityOverview from './relation-activity-overview.reducer'

export const relationActivityFeatureKey = 'relationActivity'

export interface RelationActivityActivityState {
    relationActivityOverview: RelationActivityOverview.State
}

export interface State {
    relationActivity: RelationActivityActivityState
}

const map: ActionReducerMap<RelationActivityActivityState, Action> = {
    relationActivityOverview: RelationActivityOverview.reducer
}

export const reducers = (state: RelationActivityActivityState, action: Action) => combineReducers(map)(state, action);

const selectRelationActivityState = createFeatureSelector<RelationActivityActivityState>(relationActivityFeatureKey);

export const selectState = createSelector(selectRelationActivityState, state => state)
export const selectRelationActivityOverviewState = createSelector(selectState, state => state.relationActivityOverview)
export const selectRelationActivityOverview = createSelector(selectState, state => state.relationActivityOverview.activities)

export const selectRelationActivityOverviewList = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getList)
export const selectActivityOverviewPagination = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getPagination)

export const selectRelationActivityOverviewSorting = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getSorting)
export const selectChosenRelationId = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getRelationId)
export const selectRelationActivityOverviewFilter= createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getFilter)

export const selectRelationActivityCreateError = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getCreateErrors)
export const selectRelationActivityCreateLoading = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getCreateLoading)
export const selectRelationActivity = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getRelationActivity)

export const selectGetCatchupParticipants = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getCatchupParticipants)
export const selectGetNonCatchupParticipants = createSelector(selectRelationActivityOverviewState, RelationActivityOverview.getNonCatchupParticipants)