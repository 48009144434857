import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'app-activity-dropdown',
    templateUrl: './activity-dropdown.component.html',
})
export class ActivityDropdownComponent implements OnInit {
    @Input({ required: false }) activityList: KeyValueModel[] = [];
    @Input() parent: FormGroup;

    @Output() search = new EventEmitter<{ searchQuery: string; skipCount: number }>();

    private readonly searchSubject = new Subject<{ searchQuery: string; skipCount: number }>();
    private readonly debounceTimeMs = 350;
    searchQuery = '';

    constructor() {
        this.searchSubject
            .pipe(takeUntilDestroyed())
            .pipe(debounceTime(this.debounceTimeMs))
            .subscribe((searchValue) => {
                if (searchValue.searchQuery.length !== 1) {
                    this.search.emit(searchValue);
                }
            });
    }

    ngOnInit() {
        this.search.emit({ searchQuery: this.parent.controls['activityId'].value?.toString() ?? '', skipCount: 0 });
    }

    loadMore() {
        this.searchSubject.next({ searchQuery: this.searchQuery, skipCount: this.activityList.length });
    }

    inputSearch(query: string) {
        this.searchQuery = query;
        this.searchSubject.next({ searchQuery: query, skipCount: 0 });
    }
}
