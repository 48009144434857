import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { RelationCreateModel, RelationTableItemModel, RelationUpdateModel } from '@app/modules/relation/models';
import { BillingInformationModel, TableDataModel } from '@app/shared/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RelationService {
    private readonly endpoints = {
        get: 'Relation/:id',
        getAll: 'Relation/GetAll',
        getOverview: 'Relation/GetOverview',
        changeActive: 'Relation/ChangeActive',
        create: 'Relation/Create',
        update: 'Relation/Update',
        getBillingInformation: 'Relation/GetBillingInformation',
        getByEmail: 'Relation/GetByEmail',
        resendWelcomeMail: 'Relation/ResendWelcomeMail',
        resendPassword: 'Relation/ResendPassword',
        loginAsRelation: 'Relation/LoginAsRelation',
        delete: 'Relation/Delete?id=:id',
        searchActivities: 'Activity/SearchActivity'
    };

    constructor(private readonly httpService: HttpService) {}

    getAll(): Observable<KeyValueModel[]> {
        return this.httpService.get<KeyValueModel[]>(this.endpoints.getAll);
    }

    getOverview(
        sorting: TableSortingModel,
        pagination: TablePaginationModel,
    ): Observable<TableDataModel<RelationTableItemModel>> {
        const paginationParams = parse(pagination, 'pagination');
        const sortingParams = parse(sorting, 'sorting');
        return this.httpService.get<any>(this.endpoints.getOverview + `?${paginationParams}&${sortingParams}`);
    }

    get(id: number): Observable<RelationUpdateModel> {
        return this.httpService.get<RelationUpdateModel>(this.endpoints.get.replace(':id', id.toString()));
    }

    active(id: number): Observable<any> {
        return this.httpService.put<any>(this.endpoints.changeActive, { id });
    }

    create(request: RelationCreateModel): Observable<RelationUpdateModel> {
        return this.httpService.post<RelationUpdateModel>(this.endpoints.create, request);
    }

    update(request: RelationCreateModel): Observable<RelationUpdateModel> {
        return this.httpService.post<RelationUpdateModel>(this.endpoints.update, request);
    }

    getBillingInformation(id: number): Observable<BillingInformationModel[]> {
		return this.httpService.get<BillingInformationModel[]>(this.endpoints.getBillingInformation + `?Id=${id}`);
	}

    getByEmail(email: string): Observable<RelationUpdateModel> {
        return this.httpService.get<RelationUpdateModel>(this.endpoints.getByEmail, { params: { email } });
    }

    resendWelcomeMail(id: number): Observable<void> {
        return this.httpService.post<void>(this.endpoints.resendWelcomeMail, { id });
    }

    loginAsRelation(id: number): Observable<{ link: string }> {
        return this.httpService.get<{ link: string }>(this.endpoints.loginAsRelation + `?Id=${id}`);
    }

    delete(id: number): Observable<void> {
        return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
    }

    getActivities(searchQuery: string|null, skipCount: number, relationId: number|null): Observable<KeyValueModel[]> {
        const skipCountParam = `skipCount=${skipCount}`
        const searchQueryParam = searchQuery ? `&searchQuery=${searchQuery}` : '';
        const relationIdParam = relationId ? `&activityId=${relationId}` : '';
        return this.httpService.get<KeyValueModel[]>(this.endpoints.searchActivities + `?${skipCountParam}${searchQueryParam}${relationIdParam}`);
    }
}
