<div class="page-header">
    <a routerLink="/relaties/overzicht" class="page-header__back">
        <i class="fa-regular fa-chevron-left"></i>
    </a>
    <h1 class="page-header__title">{{ 'relation.title' | translate }}</h1>
    <div class="page-header__actions"></div>
</div>

<div class="tab-container">
    <nz-tabset nzType="card">
        <nz-tab [nzTitle]="'relation.header.details' | translate">
            <app-relation-detail-form
                [relation]="relation$ | async"
                [errors]="errors$ | async"
                [loading]="loading$ | async"
                [relationTypes]="relationTypes$ | async"
                [countries]="countries$ | async"
                [billingInformation]="relationBillingInformation$ | async"
                (submitted)="submit($event)" />
        </nz-tab>
    </nz-tabset>
</div>
