import { Injectable } from '@angular/core';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as RelationActivityActions from '@app/modules/relation-activity/actions/relation-activity-api.actions';
import * as RelationActivity from '@app/modules/relation-activity/reducers';
import { RelationActivityService } from '@app/modules/relation-activity/services/relation-activity.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class RelationActivityEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly relationActivityService: RelationActivityService,
        private readonly store: Store<RelationActivity.State>,
        private readonly messageService: NzMessageService,
        private readonly translate: TranslateService,
    ) {}

    /**
     * Get effects
     */
    loadRelationActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActivityActions.GetOverview),
            concatLatestFrom(() => [
                this.store.select(RelationActivity.selectChosenRelationId),
                this.store.select(RelationActivity.selectRelationActivityOverviewFilter),
                this.store.select(RelationActivity.selectActivityOverviewPagination),
                this.store.select(RelationActivity.selectRelationActivityOverviewSorting),
            ]),
            exhaustMap(([, relationId, filters, pagination, sorting]) =>
                this.relationActivityService.getOverview(relationId, sorting, pagination, filters).pipe(
                    map((response) => RelationActivityActions.GetOverviewSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActivityActions.GetOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadRelationActivitiesFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActivityActions.FilterOverview),
            map(() => RelationActivityActions.GetOverview()),
        ),
    );

    /**
     * create  effects
     */

    createActivityRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActivityActions.Create),
            exhaustMap((action) =>
                this.relationActivityService.create(action).pipe(
                    map((activity) => RelationActivityActions.CreateSuccess(activity)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActivityActions.CreateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    createActivityRelationSuccessToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActivityActions.CreateSuccess),
            tap(() =>
                this.translate
                    .get('activity-relation.notification.add')
                    .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
            ),
            switchMap(() => of(RelationActivityActions.GetOverview(), RelationActivityActions.ResetCreate())),
        ),
    );

    createActivityRelationErrorToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActivityActions.CreateError),
                tap(() =>
                    this.translate
                        .get('relation.notification.error')
                        .subscribe((translatedMessage: string) => this.messageService.error(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    /**
     * Get acitvityRelation effects
     */

    loadActivityRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActivityActions.GetActivityRelation),
            exhaustMap((action) =>
                this.relationActivityService.getActivityRelation(action.id).pipe(
                    map((response) => RelationActivityActions.GetActivityRelationSuccess({ response })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActivityActions.GetActivityRelationError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * update  effects
     */

    updateActivityRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActivityActions.Update),
            exhaustMap((action) =>
                this.relationActivityService.update(action).pipe(
                    map((activity) => RelationActivityActions.UpdateSuccess(activity)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActivityActions.UpdateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    updateActivityRelationSuccessToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActivityActions.UpdateSuccess),
            tap(() =>
                this.translate
                    .get('activity-relation.notification.update')
                    .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
            ),
            switchMap(() => of(RelationActivityActions.GetOverview(), RelationActivityActions.ResetCreate())),
        ),
    );

    updateActivityRelationErrorToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActivityActions.UpdateError),
                tap(() =>
                    this.translate
                        .get('relation.notification.error')
                        .subscribe((translatedMessage: string) => this.messageService.error(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );
}
