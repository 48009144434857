<div nz-row>
    <div nz-col>
        <nz-table #overviewTable [nzData]="data.list" [nzLoading]="data.loading" [nzFrontPagination]="false" nzTableLayout="fixed">
            <thead>
                <tr>
                    <th *ngIf="showActivityName">
                        {{ 'certificate.fields.activity' | translate }}
                    </th>
                    <th *ngIf="showRelationName">
                        {{ 'certificate.fields.relation' | translate }}
                    </th>
                    <th>
                        {{ 'certificate.fields.name' | translate }}
                    </th>
                    <th>
                        {{ 'certificate.fields.generated-on' | translate}}
                    </th>
                    <th nzWidth="120px" claimDirective [claims]="[
                    {
                        claimType: ClaimTypes.Certificate,
                        claimValues: [ClaimValues.Read, ClaimValues.Delete]
                    }
                ]"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let row of data.list; let i = index">
                    <tr>
                        <td *ngIf="showActivityName">
                            {{ row.activityName }}
                        </td>
                        <td *ngIf="showRelationName">
                            {{ row.relationName }}
                        </td>
                        <td>
                            <a [href]="row.url" target="_blank">
                                <i class="fa-solid fa-download"></i>&nbsp;{{ row.name }}
                            </a>
                        </td>
                        <td>
                            {{ row.generatedOn | date: 'short' }}
                        </td>
                        <td>
                            <div class="table-actions">
                                <button claimDirective [claims]="[
                                {
                                    claimType: ClaimTypes.Certificate,
                                    claimValues: [ClaimValues.Delete]
                                }
                            ]" nz-button nzDanger nzType="text" nzShape="circle" nz-popconfirm nzOkDanger
                                    [nzPopconfirmTitle]="'notification.delete' | translate"
                                    [nzOkText]="'common.delete' | translate"
                                    [nzCancelText]="'common.cancel' | translate" [nzIcon]="templateIconDelete"
                                    nzPopconfirmPlacement="left" (nzOnConfirm)="delete.emit({id: row.id })">
                                    <i class="fa-regular fa-trash-can"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
        <ng-template #templateIconDelete>
            <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
        </ng-template>

    </div>
</div>