export const Constants = {
	EmailExpression: `^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$`,
	PasswordExpression: `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d!@#$%^&*()\\?_+]{6,}$`,
	UrlExpression: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&%'()*+,;=.]+$/,
	RequiredProtocolUrlExpression: /^(?:https(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&%'()*+,;=.]+$/,
	SlugExpression: /^[a-z0-9.\-_% ]*$/,
	SlugPathExpression: /^(([a-z0-9.\-_%][a-z0-9.\-_% /]*)?[a-z0-9.\-_%])?$/,
	SlugPathLenientExpression: /^(([^/].*)?[^/])?$/,
};

export enum ClaimTypes {
	ActivityLocation = 'activity-location',
	ActivityType = 'activity-type',
	BackOfficeUser = 'backoffice-user',
	ClaimCategory = 'claim-category',
	Country = 'country',
	Redirect = 'redirect',
	UserRole = 'user-role',
	WebsiteUser = 'website-user',
	RelationType = 'relation-type',
	FAQCategory = 'faq-category',
	FAQ = 'faq',
	AssetManager = 'asset-manager',
	Material = 'material',
	EmailTemplate = 'email-template',
	Review = 'review',
	Activity = 'activity',
    Page = 'page',
	DiscountCode = 'discount-code',
	News = 'news',
	Blog = 'blog',
	BlogCategory = 'blog-category',
	BlogReactions = 'blog-reaction',
	Tags = 'tags',
	ActivityTemplate = 'activity-template',
	Relation = 'relation',
	Note = 'note',
	Certificate = 'certificate',

    ActivityAsset = 'activity-asset',
    ActivityDownload = 'activity-download'
}

export enum ClaimValues {
	Create = 'create',
	Read = 'read',
	Update = 'update',
	Delete = 'delete',
}

export enum CompareType {
	EqualTo = 0,
	NotEqualTo = 1,
	GreaterThan = 2,
	GreaterThanEqualTo = 3,
	LessThan = 4,
	LessThanEqualTo = 5,
	ContainsString = 6,
	StartsWithString = 7,
	EndsWithString = 8,
}
