<ng-container [formGroup]="parent">
    <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="relationIdValidationTemplate">
            <nz-select
                formControlName="activityId"
                (nzScrollToBottom)="loadMore()"
                (nzOnSearch)="inputSearch($event)"
                nzPlaceHolder="{{ 'relation-activity.modal.choose-activities' | translate }}"
                nzShowSearch
                nzSize="large"
                class="w-100">
                <nz-option-group
                    nzLabel="{{ 'relation-activity.modal.activities' | translate }}"
                    *ngIf="activityList.length > 0">
                    <nz-option
                        *ngFor="let entry of activityList"
                        [nzValue]="entry.key"
                        nzCustomContent
                        [nzLabel]="entry.value">
                        {{ entry.value }}
                    </nz-option>
                </nz-option-group>
            </nz-select>
            <ng-template #relationIdValidationTemplate let-control>
                <app-input-validation [control]="control" [field]="'activityId'"></app-input-validation>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
</ng-container>
