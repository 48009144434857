<div nz-row>
    <div nz-col>
        <div class="page-header">
            <button nz-button nzType="primary" (click)="createActivityRelation()">
                {{ 'relation-activity.detail.add-activity' | translate }}
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>
        <app-relation-activity-overview-table
            [data]="data"
            (create)="create.emit($event)"
            (update)="editModal($event)"
            (resetOverview)="resetOverview.emit($event)"></app-relation-activity-overview-table>
    </div>
</div>
