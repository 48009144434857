import { NgModule } from '@angular/core';

import { reducers, relationActivityFeatureKey } from '@app/modules/relation-activity/reducers';
import { RelationActivityTablePartComponent } from '@app/modules/relation-activity/components/relation-activity-overview-table/relation-activity-overview-table.component';
import { RelationActivityTableComponent } from '@app/modules/relation-activity/components/relation-activity-overview/relation-activity-overview.component';
import { RelationActivityEffects } from '@app/modules/relation-activity/effects/relation-activity.effects';
import { RelationActivityRoutingModule } from '@app/modules/relation-activity/relation-activity-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { RelationActivityCreateModalComponent } from '@app/modules/relation-activity/components/relation-activity-create-modal/relation-activity-create-modal.component';
import { ActivityDropdownComponent } from '@app/modules/relation-activity/components/activity-dropdown/activity-dropdown.component';

const COMPONENTS = [RelationActivityTableComponent, RelationActivityTablePartComponent, RelationActivityCreateModalComponent, ActivityDropdownComponent];

const IMPORTS = [
    SharedModule,
    RelationActivityRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(relationActivityFeatureKey, reducers),
    EffectsModule.forFeature([RelationActivityEffects]),
];

@NgModule({
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})
export class RelationActivityModule {}
