import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';
import { ActivityDownloadModel } from '@app/modules/activity-download/models/activity-download.model';
import { ActivityAssetModel } from '@app/modules/activity-download/models/activity-asset.model';

import * as ActivityDownloadApiActions from '@app/modules/activity-download/actions/activity-download-api.actions';

const initialState: State = {
    downloads: new TableOverviewModel<ActivityDownloadModel>('Name'),
    activityId: 0,
    defaultAssets: [],
    assets: [],
    loading: false,
    errors: {},
};

export interface State {
    downloads: TableStateModel<ActivityDownloadModel>;
    activityId: number;
    defaultAssets: ActivityAssetModel[];
    assets: ActivityAssetModel[];
    loading: boolean;
    errors: { [key: string]: string[] };
}

export const reducer = createReducer(
    initialState,

    on(ActivityDownloadApiActions.GetOverview, (state) => ({
        ...state,
        downloads: { ...state.downloads, loading: true },
    })),
    on(ActivityDownloadApiActions.GetOverviewSuccess, (state, response) => ({
        ...state,
        downloads: {
            ...state.downloads,
            ...response,
            loading: false,
        },
    })),
    on(ActivityDownloadApiActions.GetOverviewError, (state) => ({
        ...state,
        downloads: { ...state.downloads, loading: false },
    })),
    on(ActivityDownloadApiActions.FilterOverview, (state, { activityId, sorting, pagination, thenBy, filter }) => ({
        ...state,
        activityId,
        downloads: {
            ...state.downloads,
            sorting: applySortingOrDefault(initialState.downloads, sorting),
            pagination: {
                ...state.downloads.pagination,
                page: pagination.page,
                pageSize: pagination.pageSize,
            },
            filter,
            thenBy,
        },
    })),


    on(ActivityDownloadApiActions.Upload, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityDownloadApiActions.UploadSuccess, (state, { assetIds }) => ({
        ...state,
        assets: state.defaultAssets.filter(x => assetIds.some(y => y === x.id)),
        loading: false,
    })),
    on(ActivityDownloadApiActions.UploadError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(ActivityDownloadApiActions.GetAssets, (state) => ({ ...state, loading: true })),
    on(ActivityDownloadApiActions.GetAssetsSuccess, (state, { response }) => ({ ...state, assets: response, loading: false })),
    on(ActivityDownloadApiActions.GetAssetsError, (state) => ({ ...state, loading: false })),

    on(ActivityDownloadApiActions.GetDefaultAssets, (state) => ({ ...state, loading: true })),
    on(ActivityDownloadApiActions.GetDefaultAssetsSuccess, (state, { response }) => ({ ...state, defaultAssets: response, loading: false })),
    on(ActivityDownloadApiActions.GetDefaultAssetsError, (state) => ({ ...state, loading: false })),

    on(ActivityDownloadApiActions.Delete, (state) => ({ ...state, loading: true })),
    on(ActivityDownloadApiActions.DeleteSuccess, (state) => ({ ...state, loading: false })),
    on(ActivityDownloadApiActions.DeleteError, (state) => ({ ...state, loading: false })),

    on(ActivityDownloadApiActions.ResetOverview, () => ({ ...initialState })),
);

export const getOverviewLoading = (state: State) => state.downloads.loading;
export const getOverview = (state: State) => state.downloads;
export const getList = (state: State) => state.downloads.list;
export const getPagination = (state: State) => state.downloads.pagination;
export const getSorting = (state: State) => state.downloads.sorting;
export const getActivityId = (state: State) => state.activityId;
export const getFilter = (state: State) => state.downloads.filter;

export const getAssets = (state: State) => state.assets;
export const getDefaultAssets = (state: State) => state.defaultAssets;
export const getLoading = (state: State) => state.loading;
export const getErrors = (state: State) => state.errors;