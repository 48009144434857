<nz-table
    #overviewTable
    [nzData]="data"
    [nzFrontPagination]="false"
    nzTableLayout="fixed">
    <thead>
        <tr>
            <th>
                {{ 'relation-activity.fields.activity' | translate }}
            </th>
            <th>
                {{ 'relation-activity.fields.role' | translate }}
            </th>
            <th>
                {{ 'relation-activity.fields.date' | translate }}
            </th>
            <th>
                {{ 'relation-activity.fields.status' | translate }}
            </th>
            <th>
                {{ 'relation-activity.fields.payment' | translate }}
            </th>
            <th
                nzWidth="120px"
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Activity,
                        claimValues: [ClaimValues.Read, ClaimValues.Delete]
                    }
                ]"></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let row of data; let i = index">
            <tr cdkDrag [cdkDragData]="row" [cdkDragDisabled]="!draggingEnabled">
                <td>
                    <a [routerLink]="['/activiteiten/detail', row.activityId]" target="_blank">
                        {{ row.activity | truncateText }}
                    </a>
                </td>
                <td>{{ ActivityAvailableParticipantsMapping[row.role] | translate }}</td>
                <td>
                    {{ row.activityStartDate }} {{ 'activity.card.through' | translate }} {{ row.activityStartDate }}
                </td>
                <td>{{ participantStatusMapping[row.status] | translate }}</td>
                <td>
                    <i class="{{ paymentStatusMapping[row.paymentStatus] }}"></i>
                </td>
                <td>
                    <div class="table-actions">
                        <button
                            claimDirective
                            [claims]="[
                                {
                                    claimType: ClaimTypes.Activity,
                                    claimValues: [ClaimValues.Read]
                                }
                            ]"
                            nz-button
                            [routerLink]=""
                            nzType="text"
                            (click)="this.update.emit(row.id)"
                            (keyup)="this.update.emit(row.id)"
                            nzShape="circle">
                            <i class="fa-regular fa-pen"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-container>
    </tbody>
</nz-table>
<ng-template #templateIconDelete>
    <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
