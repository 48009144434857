import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BackgroundService {
    private readonly endpoints = {
        getBackgroundList: 'ActivityRelation/GetBackgroundList',
    };

    constructor(private readonly httpService: HttpService) {}

    getBackgroundList(): Observable<KeyValueModel[]> {
        return this.httpService.get<KeyValueModel[]>(this.endpoints.getBackgroundList);
    }
}
