<div [formGroup]="this.formGroup">
    <div nz-row>
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">
                    {{ 'relation.fields.billing-information-type' | translate }}
                </nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="addressOptionsValidationTemplate">
                    <nz-select formControlName="id" (ngModelChange)="changeSelectedAddress($event)">
                        <nz-option
                            *ngFor="let option of address"
                            [nzValue]="option.key"
                            [nzLabel]="option.value"></nz-option>
                    </nz-select>

                    <ng-template #addressOptionsValidationTemplate let-control>
                        <app-input-validation
                            [control]="control"
                            [field]="'id'"
                            [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <div nz-row *ngIf="formGroup.controls.id.value !== 0">
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-email" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="emailValidationTemplate">
                    <input nz-input formControlName="emailAddress"/>
        
                    <ng-template #emailValidationTemplate let-control>
                        <app-input-validation [control]="control" [field]="'EmailAddress'" [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-name" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="addressValidationTemplate">
                    <input nz-input formControlName="name"/>
        
                    <ng-template #addressValidationTemplate let-control>
                        <app-input-validation [control]="control" [field]="'Name'" [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    
    <div nz-row *ngIf="formGroup.controls.id.value !== 0">
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-send-invoice-to" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="sendInvoiceToValidationTemplate">
                    <nz-select formControlName="sendInvoiceTo">
                        <nz-option
                            *ngFor="let type of billingInformationType | enumKeyValue"
                            [nzValue]="type.value"
                            [nzLabel]="billingInformationMapping[type.value] | translate"></nz-option>
                    </nz-select>

                    <ng-template #sendInvoiceToValidationTemplate let-control>
                        <app-input-validation
                            [control]="control"
                            [field]="'BillingInformationType'"
                            [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-company-name" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="companyNameValidationTemplate">
                    <input nz-input formControlName="companyName"/>
        
                    <ng-template #companyNameValidationTemplate let-control>
                        <app-input-validation [control]="control" [field]="'companyName'" [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <div nz-row *ngIf="formGroup.controls.id.value !== 0">
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-country" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="countryValidationTemplate">
                    <nz-select formControlName="countryId">
                        <nz-option
                            *ngFor="let country of countries"
                            [nzValue]="country.id"
                            [nzLabel]="country.name"></nz-option>
                    </nz-select>

                    <ng-template #countryValidationTemplate let-control>
                        <app-input-validation
                            [control]="control"
                            [field]="'CountryId'"
                            [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-zipcode" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="zipcodeValidationTemplate">
                    <input nz-input formControlName="zipCode"/>
        
                    <ng-template #zipcodeValidationTemplate let-control>
                        <app-input-validation [control]="control" [field]="'ZipCode'" [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <div nz-row *ngIf="formGroup.controls.id.value !== 0">
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-city" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="cityValidationTemplate">
                    <input nz-input formControlName="city"/>
        
                    <ng-template #cityValidationTemplate let-control>
                        <app-input-validation [control]="control" [field]="'City'" [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-address" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="addressValidationTemplate">
                    <input nz-input formControlName="address"/>
        
                    <ng-template #addressValidationTemplate let-control>
                        <app-input-validation [control]="control" [field]="'Address'" [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <div nz-row *ngIf="formGroup.controls.id.value !== 0">
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-description" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="cityValidationTemplate">
                    <input nz-input formControlName="description"/>
        
                    <ng-template #cityValidationTemplate let-control>
                        <app-input-validation [control]="control" [field]="'Description'" [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.billing-vat-number" | translate }}</nz-form-label>
                <nz-form-control [nzSpan]="24" [nzErrorTip]="vatNumberValidationTemplate">
                    <input nz-input formControlName="vatNumber"/>
        
                    <ng-template #vatNumberValidationTemplate let-control>
                        <app-input-validation [control]="control" [field]="'VATNumber'" [errors]="errors"></app-input-validation>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</div>