import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivityRelationUpdateModel } from '@app/modules/activity-relation/models/activity-relation-update.model';
import { ParticipantStatus, ParticipantStatusLabels } from '@app/modules/activity/enums/participant-status.enum';
import { PaymentStatus, PaymentStatusIcons } from '@app/modules/activity/enums/payment-status.enum';
import { RelationActivityCreateModalComponent } from '@app/modules/relation-activity/components/relation-activity-create-modal/relation-activity-create-modal.component';
import { RelationActivityTableItemModel } from '@app/modules/relation-activity/models/relation-activity-table.model';
import { RelationUpdateModel } from '@app/modules/relation/models';
import {
    ActivityAvailableParticipantsLabels,
} from '@app/shared/enums/activity-available-participants';
import { ApplicationType } from '@app/shared/enums/application-type.enum';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filter, Observable } from 'rxjs';

@Component({
    selector: 'app-relation-activity-overview',
    templateUrl: './relation-activity-overview.component.html',
    styleUrls: ['./relation-activity-overview.component.less'],
})
export class RelationActivityTableComponent implements OnChanges {
    @Input({ required: true }) data: RelationActivityTableItemModel[];

    @Input({ required: true }) relation: RelationUpdateModel;
    @Input({ required: true }) backgroundList: KeyValueModel[] = [];

    @Input({ required: true }) activities$: Observable<KeyValueModel[]>;
    @Input() relationActivity: ActivityRelationUpdateModel;

    @Input({ required: true }) loading$: Observable<boolean>;
    @Input({ required: true }) errors$: Observable<{ [key: string]: string[] }>;

    @Output() search = new EventEmitter<{ searchQuery: string; skipCount: number }>();
    @Output() create = new EventEmitter<void>();

    @Output() getActivityRelation = new EventEmitter<number>();
    @Output() resetOverview = new EventEmitter<void>();

    destroyRef = inject(DestroyRef);

    constructor(private readonly modalService: NzModalService) {}

    participantStatusMapping = ParticipantStatusLabels;
    participantActivityStatus = ParticipantStatus;

    paymentStatusMapping = PaymentStatusIcons;
    paymentActivityStatus = PaymentStatus;

    applicationType = ApplicationType;
    ActivityAvailableParticipantsMapping = ActivityAvailableParticipantsLabels;

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    draggingEnabled = false;
    nubmers: number[] = [];

    async editModal(id: number) {
        this.getActivityRelation.emit(id);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            Object.keys(changes).includes('relationActivity') &&
            !changes['relationActivity'].firstChange &&
            Object.keys(changes['relationActivity'].currentValue).length > 0
        ) {
            this.createActivityRelation(this.relationActivity);
        }
    }

    createActivityRelation(relationActivityData?: ActivityRelationUpdateModel) {
        const modal = this.modalService.create({
            nzContent: RelationActivityCreateModalComponent,
            nzWidth: '60%',
            nzMaskClosable: false,
            nzData: {
                activities$: this.activities$,
                errors$: this.errors$,
                backgroundList: this.backgroundList,
                searchEmitter: this.search,
                createEmitter: this.create,
                relation: this.relation,
                relationActivity: relationActivityData,
            },
            nzOnOk: () =>
                new Promise((resolve, reject) => {
                    this.loading$
                        .pipe(
                            takeUntilDestroyed(this.destroyRef),
                            filter((v) => !v),
                        )
                        .subscribe(() => {
                            this.errors$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((error) => {
                                Object.keys(error).length === 0 ? resolve('success') : reject('error');
                            });
                        });
                }),
        });
        modal.afterClose.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.resetOverview.emit());
    }
}
