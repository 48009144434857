import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { ActivityDownloadUploadModel } from '@app/modules/activity-download/models/activity-download-upload.model';
import { ActivityDownloadModel } from '@app/modules/activity-download/models/activity-download.model';
import { TableDataModel } from '@app/shared/models';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityDownloadService {
    private readonly endpoints = {
        overview: 'ActivityDownload/GetOverview',
        delete: 'ActivityDownload/Delete?id=:id',
        upload: 'ActivityDownload/Upload',
    };

    constructor(private readonly httpService: HttpService) { }

    getOverview(
        sorting: TableSortingModel,
        pagination: TablePaginationModel,
        activityId: number,
        filter?: TableFilterModel[],
    ): Observable<TableDataModel<ActivityDownloadModel>> {
        const paginationParams = parse(pagination, 'pagination');
        const sortingParams = parse(sorting, 'sorting');
        const activityIdParam = `activityId=${activityId}`;
        const sortingFilter = filter ? `&${parse(filter, 'Filters')}` : '';

        return this.httpService.get<TableDataModel<ActivityDownloadModel>>(
            this.endpoints.overview +
            `?${paginationParams}&${sortingParams}&${activityIdParam}${sortingFilter}`
        );
    }

    delete(id: number): Observable<void> {
        return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
    }

    upload(request: ActivityDownloadUploadModel): Observable<ActivityDownloadUploadModel> {
        const formData = new FormData();

        formData.append('file', request.file);

        request.assetIds.forEach((assetId) => {
            formData.append('assetIds[]', assetId.toString());
        });

        formData.append('activityId', request.activityId.toString());

        return this.httpService.postFile<ActivityDownloadUploadModel>(this.endpoints.upload, formData);
    }
}
