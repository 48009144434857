import { NgModule } from "@angular/core";
import { ActivityDownloadModule } from "@app/modules/activity-download/activity-download.module";
import { ActivityRelationModule } from "@app/modules/activity-relation/activity-relation.module";
import { ActivityRoutingModule } from "@app/modules/activity/activity-routing.module";
import { ActivityDetailFormComponent } from "@app/modules/activity/components/activity-detail-form/activity-detail-form.component";
import { ActivityOverviewTableComponent } from "@app/modules/activity/components/activity-overview-table/activity-overview-table.component";
import { ActivityTemplateModalComponent } from "@app/modules/activity/components/activity-template-modal/activity-template-modal.component";
import { ActivityViewCardComponent } from "@app/modules/activity/components/activity-view-card/activity-view-card.component";
import { ActivityInfoViewComponent } from "@app/modules/activity/components/activity-view-info/activity-view-info.component";
import { ActivityEffects } from "@app/modules/activity/effects/activity.effects";
import { ActivityDetailComponent } from "@app/modules/activity/pages/detail/activity-detail.component";
import { ActivityOverviewComponent } from "@app/modules/activity/pages/overview/activity-overview.component";
import { ActivityViewComponent } from "@app/modules/activity/pages/view/activity-view.component";
import { activityFeatureKey, reducers } from "@app/modules/activity/reducers";
import { BackgroundModule } from "@app/modules/background/background.module";
import { CertificateModule } from "@app/modules/certificate/certificate.module";
import { NoteModule } from "@app/modules/note/note.module";
import { SharedModule } from "@app/shared/shared.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
    ActivityOverviewComponent,
    ActivityViewComponent,
    ActivityDetailComponent,
    ActivityTemplateModalComponent,
    ActivityOverviewTableComponent,
    ActivityDetailFormComponent,
    ActivityViewCardComponent,
    ActivityInfoViewComponent,
];

const IMPORTS = [
    SharedModule,
    ActivityRoutingModule,
    NzDividerModule,
    ActivityRelationModule,
    ActivityDownloadModule,
    CertificateModule,
    NoteModule,
    BackgroundModule,
    StoreModule.forFeature(activityFeatureKey, reducers),
    EffectsModule.forFeature([ActivityEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class ActivityModule { }
