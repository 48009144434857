import { Component, EventEmitter, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckboxFormGroup, getCheckedValues } from '@app/core/models/checkbox-form-group.model';
import { ActivityAssetModel } from '@app/modules/activity-download/models/activity-asset.model';
import { ActivityDownloadUploadModel } from '@app/modules/activity-download/models/activity-download-upload.model';
import { isFormValid, processBackendValidatorsErrors } from '@app/shared/utilities/validation.utilities';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { filter, Observable } from 'rxjs';

@Component({
    selector: 'app-activity-download-upload-modal',
    templateUrl: './activity-download-upload-modal.component.html',
    styleUrls: ['./activity-download-upload-modal.component.less']
})
export class ActivityDownloadUploadModalComponent implements OnInit {
    errors: { [key: string]: string[] } = {};

    readonly nzModalData = inject(NZ_MODAL_DATA);

    assets: ActivityAssetModel[] = this.nzModalData.assets;
    defaultAssets: ActivityAssetModel[] = this.nzModalData.defaultAssets;
    upload: EventEmitter<ActivityDownloadUploadModel> = this.nzModalData.uploadEmitter;
    activityId: number = this.nzModalData.activityId;
    errors$: Observable<{ [key: string]: string[] }> = this.nzModalData.errors$;

    formGroup = new FormGroup({
        file: new FormControl<File>(null),
        activityId: new FormControl<number>(this.activityId, Validators.required),
        assetIds: new FormControl<number[]>([]),
        assets: new FormArray<CheckboxFormGroup>([]),
    });

    modal = inject(NzModalRef);

    constructor() {
        this.errors$
            .pipe(
                takeUntilDestroyed(),
                filter((errors) => Object.keys(errors).length > 0),
            )
            .subscribe((errors) => {
                processBackendValidatorsErrors(errors, this.formGroup);
                this.errors = errors;
            });
    }

    ngOnInit(): void {
        this.formGroup.patchValue({
            activityId: this.activityId,
        });

        this.assets.forEach((asset) => {
            this.formGroup.controls.assets.push(new CheckboxFormGroup({ label: asset.name, value: asset.id, checked: true }));
        });

        this.defaultAssets.filter(asset => !this.assets.some(x => x.id === asset.id)).forEach((asset) => {
            this.formGroup.controls.assets.push(new CheckboxFormGroup({label: asset.name, value: asset.id, checked: false}));
        });
    }

    destroyModal(): void {
        this.modal.destroy();
    }

    submit() {
        this.formGroup.patchValue({ assetIds: getCheckedValues(this.formGroup.controls.assets)});
        if (isFormValid(this.formGroup)) {
            this.upload.emit({
                file: this.formGroup.value.file,
                activityId: this.formGroup.value.activityId,
                assetIds: this.formGroup.value.assetIds,
            });
            this.modal.triggerOk();
        }
    }

    beforeUpload = (file: any): boolean => {
        this.formGroup.patchValue({file})
        return false;
    };

    removeFile() {
        this.formGroup.controls.file.reset();
    }
}
