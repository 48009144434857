import { ErrorResponse } from "@app/core/models";
import { ActivityRelationCreateModel } from "@app/modules/activity-relation/models/activity-relation-create.model";
import { ActivityRelationUpdateModel } from "@app/modules/relation-activity/models/activity-relation-update.model";
import { RelationActivityTableItemModel } from "@app/modules/relation-activity/models/relation-activity-table.model";
import { TableDataModel } from "@app/shared/models";
import { TableFilterModel } from "@app/shared/models/table/table-filter.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[RelationActivity] GetOverview");
export const GetOverviewError = createAction("[RelationActivity] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[RelationActivity] GetOverviewSuccess", props<TableDataModel<RelationActivityTableItemModel>>());

export const FilterOverview = createAction("[RelationActivity] FilterOverview", props<{filter?: TableFilterModel[], thenBy?: TableSortingModel[], relationId: number, sorting?: TableSortingModel, pagination: { page: number, pageSize: number }}>());

export const ResetOverview = createAction("[RelationActivity] ResetOverview");
export const ResetCreate = createAction("[RelationActivity] ResetCreate");

export const Create = createAction("[RelationActivity] Create", props<ActivityRelationCreateModel>());
export const CreateError = createAction("[RelationActivity] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[RelationActivity] CreateSuccess", props<ActivityRelationCreateModel>());

export const Update = createAction("[RelationActivity] Update", props<ActivityRelationUpdateModel>());
export const UpdateError = createAction("[RelationActivity] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[RelationActivity] UpdateSuccess", props<ActivityRelationUpdateModel>());

export const GetActivityRelation = createAction("[RelationActivity] GetActivityRelation", props<{ id: number }>());
export const GetActivityRelationError = createAction("[RelationActivity] GetActivityRelationError", props<{ response: ErrorResponse }>());
export const GetActivityRelationSuccess = createAction("[RelationActivity] GetActivityRelationSuccess", props<{ response: ActivityRelationUpdateModel }>());