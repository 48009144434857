import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { ActivityRelationCreateModel } from '@app/modules/activity-relation/models/activity-relation-create.model';
import { ActivityRelationUpdateModel } from '@app/modules/relation-activity/models/activity-relation-update.model';
import { RelationActivityTableItemModel } from '@app/modules/relation-activity/models/relation-activity-table.model';
import { TableDataModel } from '@app/shared/models';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RelationActivityService {
    private readonly endpoints = {
        overview: 'ActivityRelation/GetRelationActivitiesOverview',
        create: 'ActivityRelation/CreateFromRelation',
        getActivityRelation: 'ActivityRelation/:id',
        update: 'ActivityRelation/UpdateFromRelation'
    };

    constructor(private readonly httpService: HttpService) {}

    getOverview(
        relationId: number,
        sorting: TableSortingModel,
        pagination: TablePaginationModel,
        filter?: TableFilterModel[],
    ): Observable<TableDataModel<RelationActivityTableItemModel>> {
        const activityIdParam = `relationId=${relationId}`;
        const sortingFilter = filter ? `&${parse(filter, 'Filters')}` : '';
        const paginationParams = parse(pagination, 'pagination');
        const sortingParams = parse(sorting, 'sorting');

        return this.httpService.get<TableDataModel<RelationActivityTableItemModel>>(
            this.endpoints.overview + `?${activityIdParam}${sortingFilter}&${paginationParams}&${sortingParams}`,
        );
    }

    create(request: ActivityRelationCreateModel): Observable<ActivityRelationUpdateModel> {
        return this.httpService.post<ActivityRelationUpdateModel>(this.endpoints.create, request);
    }

    getActivityRelation(id: number): Observable<ActivityRelationUpdateModel> {
        return this.httpService.get<ActivityRelationUpdateModel>(
            this.endpoints.getActivityRelation.replace(':id', id.toString()),
        );
    }

    update(request: ActivityRelationUpdateModel): Observable<ActivityRelationUpdateModel> {
        return this.httpService.post<ActivityRelationUpdateModel>(this.endpoints.update, request);
    }
}
