import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as BackgroundOverview from './background-overview.reducer';

export const backgroundFeatureKey = 'background';

export interface BackgroundState {
    backgroundOverview: BackgroundOverview.State;
}

export interface State {
    background: BackgroundState;
}

const map: ActionReducerMap<BackgroundState, Action> = {
    backgroundOverview: BackgroundOverview.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: BackgroundState, action: Action) => combineReducers(map)(state, action);

const selectBackgroundState = createFeatureSelector<BackgroundState>(backgroundFeatureKey);

export const selectState = createSelector(selectBackgroundState, (state) => state);
export const selectBackgroundOverviewState = createSelector(selectState, (state) => state.backgroundOverview);

export const selectBackgroundList = createSelector(selectBackgroundOverviewState, BackgroundOverview.getList);
