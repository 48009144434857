import { Injectable } from '@angular/core';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as BackgroundActions from '@app/modules/background/actions/background-api.actions';
import { BackgroundService } from '@app/modules/background/services/background.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

@Injectable()
export class BackgroundEffects {
    constructor(private readonly actions$: Actions, private readonly backgroundService: BackgroundService) {}

    /**
     * get backgrounds effects
     */
    loadBackgroundList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BackgroundActions.GetBackgroundList),
            exhaustMap(() =>
                this.backgroundService.getBackgroundList().pipe(
                    map((response) => BackgroundActions.GetBackgroundListSuccess({ response })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(BackgroundActions.GetBackgroundListError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );
}
