import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoModule } from '@app/modules/demo/demo.module';

import { BackofficeLayoutComponent } from '@app/core/containers/backoffice-layout/backoffice-layout.component';
import { PublicLayoutComponent } from '@app/core/containers/public-layout/public-layout.component';
import { authGuard } from '@app/core/guards';
import { TokenService } from '@app/core/services/token/token.service';
import { ActivityLocationsModule } from '@app/modules/activity-location/activity-location.module';
import { ActivityTypesModule } from '@app/modules/activity-type/activity-type.module';
import { AuthModule } from '@app/modules/auth/auth.module';
import { BlogCategoryModule } from '@app/modules/blog-category/blog-category.module';
import { BlogModule } from '@app/modules/blogs/blog.module';
import { ClaimCategoriesModule } from '@app/modules/claim-categories/claim-category.module';
import { CountryModule } from '@app/modules/countries/country.module';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { DiscountCodeModule } from '@app/modules/discount-codes/discount-code.module';
import { EmailTemplateModule } from '@app/modules/email-template/email-template.module';
import { ErrorModule } from '@app/modules/error/error.module';
import { FAQCategoryModule } from '@app/modules/faq-categories/faq-category.module';
import { FAQModule } from '@app/modules/faq/faq.module';
import { MaterialsModule } from '@app/modules/materials/material.module';
import { NewsModule } from '@app/modules/news/news.module';
import { PageModule } from '@app/modules/page/page.module';
import { RedirectsModule } from '@app/modules/redirects/redirect.module';
import { RelationTypesModule } from '@app/modules/relation-type/relation-type.module';
import { RelationModule } from '@app/modules/relation/relation.module';
import { ReviewsModule } from '@app/modules/review/review.module';
import { TagModule } from '@app/modules/tag/tag.module';
import { UserRolesModule } from '@app/modules/user-roles/user-role.module';
import { UsersModule } from '@app/modules/users/user.module';
import { MobileModule } from '@app/mobile/mobile.module';
import { ActivityTemplatesModule } from '@app/modules/activity-template/activity-template.module';
import { ActivityModule } from '@app/modules/activity/activity.module';

const routes: Routes = [
	{ path: '', redirectTo: 'auth', pathMatch: 'full' },

	{ path: '', component: PublicLayoutComponent, children: [{ path: 'auth', loadChildren: () => AuthModule }] },

	{
		path: '',
		component: BackofficeLayoutComponent,
		canMatch: [() => inject(TokenService).hasToken()],
		loadChildren: () => ErrorModule,
	},
	{ path: '', component: PublicLayoutComponent, loadChildren: () => ErrorModule },

	{
		path: '',
		component: BackofficeLayoutComponent,
		canActivate: [authGuard],
		children: [
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: 'dashboard', loadChildren: () => DashboardModule },
			{ path: 'relaties', loadChildren: () => RelationModule },
			{ path: 'relatie-typen', loadChildren: () => RelationTypesModule },
			{ path: 'reviews', loadChildren: () => ReviewsModule },
			{ path: 'paginas', loadChildren: () => PageModule },
			{ path: 'blogs', loadChildren: () => BlogModule },
			{ path: 'activiteit-typen', loadChildren: () => ActivityTypesModule },
			{ path: 'activiteit-locaties', loadChildren: () => ActivityLocationsModule },
			{ path: 'activiteit-templates', loadChildren: () => ActivityTemplatesModule },
			{ path: 'activiteiten', loadChildren: () => ActivityModule },
			{ path: 'redirects', loadChildren: () => RedirectsModule },
			{ path: 'landen', loadChildren: () => CountryModule },
			{ path: 'rechten', loadChildren: () => ClaimCategoriesModule },
			{ path: 'gebruikers', loadChildren: () => UsersModule },
			{ path: 'gebruikersgroepen', loadChildren: () => UserRolesModule },
			{ path: 'faq', loadChildren: () => FAQModule },
			{ path: 'faq-categorieen', loadChildren: () => FAQCategoryModule },
			{ path: 'materialen', loadChildren: () => MaterialsModule },
			{ path: 'nieuws', loadChildren: () => NewsModule },
			{ path: 'email-templates', loadChildren: () => EmailTemplateModule },
			{ path: 'tags', loadChildren: () => TagModule },
			{ path: 'blog-categorieen', loadChildren: () => BlogCategoryModule },
			{ path: 'kortingscodes', loadChildren: () => DiscountCodeModule },

			{ path: 'demo', loadChildren: () => DemoModule },
		],
	},

	{ path: 'mobiel', loadChildren: () => MobileModule },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { enableTracing: false })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
