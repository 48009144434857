import { Component, inject } from '@angular/core';
import * as Relation from '@app/modules/relation/reducers';

import * as RelationApiActions from '@app/modules/relation/actions/relation-api.actions';
import { RelationCreateModel, RelationUpdateModel } from '@app/modules/relation/models';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-relation-detail',
    templateUrl: './relation-detail.component.html',
})
export class RelationDetailComponent {
    modalService = inject(NzModalService);
    translate = inject(TranslateService);
    router = inject(Router);

    loading$ = this.store.select(Relation.selectDetailLoading);
    errors$ = this.store.select(Relation.selectDetailErrors);
    relation$ = this.store.select(Relation.selectDetailRelation);
    relationTypes$ = this.store.select(Relation.selectDetailRelationTypes);
    countries$ = this.store.select(Relation.selectDetailCountries);
    duplicatedRelation$ = this.store.select(Relation.selectDuplicatedRelation);
    relationBillingInformation$ = this.store.select(Relation.selectDetailBillingInformation);

    relationId: null | number = null;
    private email = '';

    constructor(private readonly store: Store<State>) {
        this.store.dispatch(RelationApiActions.GetRelationTypes());
        this.store.dispatch(RelationApiActions.GetCountries());

        this.duplicatedRelation$
            .pipe(takeUntilDestroyed())
            .pipe(filter((duplicatedRelation) => !!duplicatedRelation.id))
            .subscribe(duplicatedRelation => {
                this.modalService.confirm({
                    nzTitle: this.translate.instant('relation.modal.duplicate.title'),
                    nzContent: this.translate.instant(
                        'relation.modal.duplicate.body', { email: duplicatedRelation.email }
                    ),
                    nzBodyStyle: { 'white-space': 'pre-wrap' },
                    nzOkText: this.translate.instant('relation.modal.duplicate.open'),
                    nzCancelText: this.translate.instant('common.cancel'),
                    nzOnOk: () => {
                        const url = this.router.serializeUrl(this.router.createUrlTree(['/relaties/detail/', duplicatedRelation.id]));
                        window.open(url, '_blank');
                    }
                });
            });

        this.relation$
            .pipe(
                takeUntilDestroyed(),
                filter(relation => !!relation?.id),
            )
            .subscribe(relation => {
                this.relationId = relation.id
                this.store.dispatch(RelationApiActions.GetRelationBillingInformation({ id: this.relationId }));
            });

      
            this.errors$
            .pipe(takeUntilDestroyed())
            .pipe(filter((error) => Object.hasOwn(error, "Email") && error['Email'].some(x => x === 'DUPLICATE')))
            .subscribe(() => this.store.dispatch(RelationApiActions.GetByEmail({ email: this.email })));
    }

    submit(relation: RelationCreateModel | RelationUpdateModel) {
        this.email = relation.email;

        this.store.dispatch(
            Object.hasOwn(relation, "id")
                ? RelationApiActions.Update(relation as RelationUpdateModel)
                : RelationApiActions.Create(relation),
        );
    }
}
