import { ErrorResponse } from "@app/core/models";
import { CertificateTableItemModel } from "@app/modules/certificate/models/certificate-table.model";
import { TableDataModel } from "@app/shared/models";
import { TableFilterModel } from "@app/shared/models/table/table-filter.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const FilterOverview = createAction("[Certificate] FilterOverview", props<{ filter?: TableFilterModel[], thenBy?: TableSortingModel[], sorting?: TableSortingModel, activityId?: number, relationId?: number, pagination: { page: number, pageSize: number } }>());

export const GetOverview = createAction("[Certificate] GetOverview");
export const GetOverviewError = createAction("[Certificate] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Certificate] GetOverviewSuccess", props<TableDataModel<CertificateTableItemModel>>());

export const ResetOverview = createAction("[Certificate] ResetOverview");

export const Delete = createAction("[Certificate] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Certificate] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Certificate] DeleteSuccess");