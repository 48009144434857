<div>
    <div *nzModalTitle>
        {{ 'relation-activity.modal.add-title' | translate }}
    </div>
    <form nz-form [formGroup]="formGroup">
        <section class="form-section">
            <header>
                <h5 class="form-section__title">
                    {{ 'relation-activity.modal.activity' | translate }}
                </h5>
            </header>

            <div nz-row>
                <div nz-col [nzSpan]="24">
                    <app-activity-dropdown
                        [parent]="formGroup"
                        [activityList]="(this.activities$ | async)!"
                        (search)="searchEmitterSearch($event)" />
                </div>
            </div>
        </section>
        <div *ngIf="this.formGroup.controls.activityId.value">
            <section class="form-section">
                <header>
                    <h5 class="form-section__title">
                        {{ 'activity-relation.modal.header.application' | translate }}
                    </h5>
                </header>
                <div nz-row>
                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.specific-date' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="specificDateValidationTemplate">
                            <nz-select
                                class="w-100"
                                nzPlaceHolder="{{ 'activity-relation.modal.add.specific-date' | translate }}"
                                formControlName="specificDate">
                                <nz-option
                                    *ngFor="let activityType of this.datesArray"
                                    nzLabel="{{ activityType.value | date }}"
                                    [nzValue]="activityType.key"></nz-option>
                            </nz-select>
                            <ng-template #specificDateValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'specificDate'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.role' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="roleValidationTemplate">
                            <nz-select class="w-100" formControlName="role">
                                <nz-option
                                    *ngFor="let stat of roleTypeLabels | enumKeyValue"
                                    [nzValue]="stat.value"
                                    [nzLabel]="roleTypeMapping[stat.value] | translate"></nz-option>
                            </nz-select>
                            <ng-template #roleValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'role'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.application-type' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="applicationTypeValidationTemplate">
                            <nz-select class="w-100" formControlName="applicationType">
                                <nz-option
                                    *ngFor="let stat of applicationTypes | enumKeyValue"
                                    [nzValue]="stat.value"
                                    [nzLabel]="applicationTypeMapping[stat.value] | translate"></nz-option>
                            </nz-select>
                            <ng-template #applicationTypeValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'applicationType'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.status' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="statusValidationTemplate">
                            <nz-select class="w-100" formControlName="status">
                                <nz-option
                                    *ngFor="let stat of participantStatus | enumKeyValue"
                                    [nzValue]="stat.value"
                                    [nzLabel]="participantStatusMapping[stat.value] | translate"></nz-option>
                            </nz-select>
                            <ng-template #statusValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'status'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.payment-status' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="paymentStatusValidationTemplate">
                            <nz-select class="w-100" formControlName="paymentStatus">
                                <nz-option
                                    *ngFor="let stat of paymentStatus | enumKeyValue"
                                    [nzValue]="stat.value"
                                    [nzLabel]="paymentStatusMapping[stat.value] | translate"></nz-option>
                            </nz-select>
                            <ng-template #paymentStatusValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'paymentStatus'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.payment-type' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="paymentTypeValidationTemplate">
                            <nz-select class="w-100" formControlName="paymentType">
                                <nz-option
                                    *ngFor="let stat of paymentTypes | enumKeyValue"
                                    [nzValue]="stat.value"
                                    [nzLabel]="paymentTypeMapping[stat.value] | translate"></nz-option>
                            </nz-select>
                            <ng-template #paymentTypeValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'paymentType'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.employer-pays' | translate }}
                        </nz-form-label>

                        <nz-form-control [nzSpan]="24" [nzErrorTip]="employerPaysValidationTemplate">
                            <nz-select formControlName="employerPays">
                                <nz-option [nzLabel]="'common.yes' | translate" [nzValue]="true"></nz-option>
                                <nz-option [nzLabel]="'common.no' | translate" [nzValue]="false"></nz-option>
                            </nz-select>
                            <ng-template #employerPaysValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'employerPays'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.invoice-number' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="invoiceNumberValidationTemplate">
                            <input
                                nz-input
                                placeholder="{{ 'activity-relation.modal.add.invoice-number' | translate }}"
                                formControlName="invoiceNumber" />
                            <ng-template #invoiceNumberValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'invoiceNumber'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item nz-col nzSpan="6" class="mb-0">
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-relation.modal.add.result-status' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="resultValidationTemplate">
                            <nz-select class="w-100" formControlName="result">
                                <nz-option
                                    *ngFor="let stat of resultStatus | enumKeyValue"
                                    [nzValue]="stat.value"
                                    [nzLabel]="resultStatusMapping[stat.value] | translate"></nz-option>
                            </nz-select>
                            <ng-template #resultValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'result'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </section>

            <section class="form-section">
                <header>
                    <h5 class="form-section__title">
                        {{ 'activity-relation.modal.header.additional-information' | translate }}
                    </h5>
                </header>

                <div nz-row>
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">
                                {{ 'activity-relation.modal.add.backgrounds' | translate }}
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="intakeValidationTemplate">
                                <div formGroupName="backgrounds">
                                    <div class="ms-auto" nz-row *ngFor="let option of this.backgroundList">
                                        <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                                        <label
                                            nz-checkbox
                                            nzValue="{{ option.key }}"
                                            formControlName="{{ option.key }}">
                                            {{ 'backgrounds.' + option.value | translate }}
                                        </label>
                                    </div>
                                </div>
                                <ng-template #intakeValidationTemplate let-control>
                                    <app-input-validation
                                        [control]="control"
                                        [field]="'backgrounds'"
                                        [errors]="errors"></app-input-validation>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">
                                {{ 'activity-relation.modal.add.intake' | translate }}
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="intakeValidationTemplate">
                                <nz-radio-group formControlName="intake">
                                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                                    <label nz-radio [nzValue]="true">{{ 'common.yes' | translate }}</label>
                                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                                    <label nz-radio [nzValue]="false">{{ 'common.no' | translate }}</label>
                                </nz-radio-group>
                                <ng-template #intakeValidationTemplate let-control>
                                    <app-input-validation
                                        [control]="control"
                                        [field]="'intake'"
                                        [errors]="errors"></app-input-validation>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">
                                {{ 'activity-relation.modal.add.allow-video' | translate }}
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="allowVideoValidationTemplate">
                                <nz-radio-group formControlName="allowVideo">
                                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                                    <label nz-radio [nzValue]="true">{{ 'common.yes' | translate }}</label>
                                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                                    <label nz-radio [nzValue]="false">{{ 'common.no' | translate }}</label>
                                </nz-radio-group>
                                <ng-template #allowVideoValidationTemplate let-control>
                                    <app-input-validation
                                        [control]="control"
                                        [field]="'allowVideo'"
                                        [errors]="errors"></app-input-validation>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">
                                {{ 'activity-relation.modal.add.introduction' | translate }}
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24">
                                <app-input-validation
                                    [control]="formGroup.controls.message"
                                    [field]="'message'"
                                    [errors]="errors"></app-input-validation>
                                <app-editor
                                    [control]="formGroup.controls.message"
                                    [isDraggable]="false"
                                    [isReadOnly]="false"
                                    [simpleEditor]="true"></app-editor>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </section>
        </div>
    </form>

    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="destroyModal()">{{ 'common.cancel' | translate }}</button>
        <button nz-button nzType="primary" (click)="submit()">{{ 'common.add' | translate }}</button>
    </div>
</div>
