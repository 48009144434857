import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CountryModel } from '@app/modules/countries/models';
import { BillingInformationType, BillingInformationTypeOptions } from '@app/shared/enums/billing-information.type';
import { BillingInformationModel } from '@app/shared/models';
import { BillingInformationFormGroup } from '@app/shared/models/billing-information/billing-information-form-group';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';

@Component({
	selector: 'app-billing-information-form',
    styleUrl: './billing-information-form.component.less',
	templateUrl: './billing-information-form.component.html',
})
export class BillingInformationFormComponent implements OnChanges{
    @Input({ required: true }) formGroup: BillingInformationFormGroup;
    @Input({ required: true }) countries: CountryModel[] = [];
    @Input() billingInformation: BillingInformationModel[] = [];
	@Input() errors: { [key: string]: string[] } = {};

	@Input() required = false;
	@Input() disabled = false;

	billingInformationMapping = BillingInformationTypeOptions;
	billingInformationType = BillingInformationType;

	address: KeyValueModel[] = [];

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["billingInformation"]) {
			this.address = [
				{ key: 0, value: 'Persoonlijk adres' },
				...changes["billingInformation"].currentValue.map((x: BillingInformationModel) => ({ key: x.id, value: x.name })),
				{ key: -1, value: 'Nieuw adres toevoegen' }
			];
		}
	}

	changeSelectedAddress(selectedAddressId: number) {
		if (selectedAddressId > 0) {
			const selectedData = this.billingInformation.find((item) => item.id === selectedAddressId);

			if (selectedData) {
				this.formGroup.patchValue({
					address: selectedData.address,
					city: selectedData.city,
					companyName: selectedData.companyName,
					countryId: selectedData.countryId,
					emailAddress: selectedData.emailAddress,
					name: selectedData.name,
					zipCode: selectedData.zipCode,
					vatNumber: selectedData.vatNumber,
					sendInvoiceTo: selectedData.billingInformationType,
					description: selectedData.description,
				});
				this.billingInformationMapping = BillingInformationTypeOptions;
				this.billingInformationType = BillingInformationType;
			}
		}

		if (selectedAddressId === -1) {
			this.formGroup.reset({
				id: this.formGroup.controls.id.value,
				address: null,
				city: null,
				companyName: null,
				countryId: null,
				emailAddress: null,
				name: null,
				zipCode: null,
				vatNumber: null,
				sendInvoiceTo: null,
				description: null,
			});
		}
	}
}
