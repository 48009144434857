import { ErrorResponse } from "@app/core/models";
import { ActivityAssetModel } from "@app/modules/activity-download/models/activity-asset.model";
import { ActivityDownloadUploadModel } from "@app/modules/activity-download/models/activity-download-upload.model";
import { ActivityDownloadModel } from "@app/modules/activity-download/models/activity-download.model";
import { TableDataModel } from "@app/shared/models";
import { TableFilterModel } from "@app/shared/models/table/table-filter.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[ActivityDownload] GetOverview");
export const GetOverviewError = createAction("[ActivityDownload] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[ActivityDownload] GetOverviewSuccess", props<TableDataModel<ActivityDownloadModel>>());

export const FilterOverview = createAction("[ActivityDownload] FilterOverview", props<{filter?: TableFilterModel[], thenBy?: TableSortingModel[], activityId: number, sorting?: TableSortingModel, pagination: { page: number, pageSize: number }}>());

export const ResetOverview = createAction("[ActivityDownload] ResetOverview");

export const Delete = createAction("[ActivityDownload] Delete", props<{ id: number }>());
export const DeleteError = createAction("[ActivityDownload] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[ActivityDownload] DeleteSuccess");

export const Upload = createAction("[ActivityDownload] Upload", props<ActivityDownloadUploadModel>());
export const UploadError = createAction("[ActivityDownload] UploadError", props<{ response: ErrorResponse }>());
export const UploadSuccess = createAction("[ActivityDownload] UploadSuccess", props<ActivityDownloadUploadModel>());

export const GetDefaultAssets = createAction("[ActivityDownload] GetDefaultAssets");
export const GetDefaultAssetsError = createAction("[ActivityDownload] GetDefaultAssetsError", props<{ response: ErrorResponse }>());
export const GetDefaultAssetsSuccess = createAction("[ActivityDownload] GetDefaultAssetsSuccess", props<{ response: ActivityAssetModel[] }>());

export const GetAssets = createAction("[ActivityDownload] GetAssets", props<{ activityId: number }>());
export const GetAssetsError = createAction("[ActivityDownload] GetAssetsError", props<{ response: ErrorResponse }>());
export const GetAssetsSuccess = createAction("[ActivityDownload] GetAssetsSuccess", props<{ response: ActivityAssetModel[] }>());