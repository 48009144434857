<div class="page-header">
    <a routerLink="/relaties/overzicht" class="page-header__back">
        <i class="fa-regular fa-chevron-left"></i>
    </a>
    <h1 class="page-header__title">{{ 'relation.title' | translate }}</h1>
    <div class="page-header__actions">
        <button [routerLink]="['/relaties/bewerken', (relation$ | async)?.id]" nz-button nzType="primary">
            {{ 'relation.detail.edit-title' | translate }}
            <i class="fa-regular fa-pen"></i>
        </button>
    </div>
</div>

<div nz-row [nzGutter]="16">
    <div nz-col [nzSpan]="24">
        <app-relation-view-card [relation]="(relation$ | async)!" [loading]="loading$ | async" />
    </div>
</div>
<div nz-row [nzGutter]="16">
    <div nz-col [nzSpan]="18">
        <div class="tab-container">
            <nz-tabset nzType="card">
                <nz-tab [nzTitle]="'relation.header.details' | translate">
                    <app-relation-view-info
                        [relation]="(relation$ | async)!"
                        [loading]="loading$ | async"
                        [errors]="errors$ | async" />
                </nz-tab>
                <nz-tab [nzTitle]="'relation.header.activities' | translate" *ngIf="[relationTypeEnum.GuestClient, relationTypeEnum.Student, relationTypeEnum.Guest].includes(this.relationType)">
                    <app-relation-activity-overview [relationActivity]="activityRelation$ | async" (getActivityRelation)="getActivityRelation($event)" (resetOverview)="resetCreateModal()" [errors$]="relationActivityCreateError$" [loading$]="relationActivityCreateLoading$" (create)="createActivityRelation($event)" (search)="searchActivity($event)" [activities$]="activityList$" [backgroundList]="(backgroundList$ | async)!" [data]="(relationNonCatchupActivities$ | async)!" [relation]="(relation$ | async)!" />
                </nz-tab>
                <nz-tab [nzTitle]="'relation.header.catch-up' | translate" *ngIf="[relationTypeEnum.GuestClient, relationTypeEnum.Student, relationTypeEnum.Guest].includes(this.relationType)">
                    <app-relation-activity-overview (getActivityRelation)="getActivityRelation($event)" (resetOverview)="resetCreateModal()" [errors$]="relationActivityCreateError$" [loading$]="relationActivityCreateLoading$" (create)="createActivityRelation($event)" (search)="searchActivity($event)" [activities$]="activityList$" [backgroundList]="(backgroundList$ | async)!" [data]="(relationCatchupActivities$ | async)!" [relation]="(relation$ | async)!" />
                </nz-tab>
                <nz-tab [nzTitle]="'relation.header.certificates' | translate">
                    <app-certificate-overview [data]="certificates$ | async" [showActivityName]="true" (delete)="deleteCertificate($event)"></app-certificate-overview>
                </nz-tab>
            </nz-tabset>
        </div>
    </div>
    <div nz-col [nzSpan]="6">
        <div nz-row>
            <div nz-col [nzSpan]="24">
                <app-toolbox-sidebar
                    (delete)="delete()"
                    (loginAsRelation)="loginAsRelation()"
                    (resendWelcomeMail)="resendWelcomeMail()" />
            </div>
            <div nz-col [nzSpan]="24">
                <app-notes-sidebar
                    [note$]="note$"
                    [errors$]="noteErrors$"
                    [loading$]="noteLoading$"
                    [notes]="(notes$ | async)!"
                    (createNote)="createNote($event)"
                    (setNoteImportant)="setNoteImportant($event)"
                    (doEditNote)="editNote($event)"
                    (doDeleteNote)="deleteNote($event)"></app-notes-sidebar>
            </div>
        </div>
    </div>
</div>
