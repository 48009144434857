import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { ActivityRelationCreateModel } from '@app/modules/activity-relation/models/activity-relation-create.model';
import { ActivityRelationTableItemModel } from '@app/modules/activity-relation/models/activity-relation-table.model';
import { ActivityRelationUpdateModel } from '@app/modules/activity-relation/models/activity-relation-update.model';
import { TableDataModel } from '@app/shared/models';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityRelationService {
    private readonly endpoints = {
        overview: 'ActivityRelation/GetOverview',
        createReservation: 'ActivityRelation/CreateReservation',
        delete: 'ActivityRelation/Delete?id=:id',
        deleteRelation: 'ActivityRelation/DeleteReservation?id=:id',
        create: 'ActivityRelation/Create',
        getActivityRelation: 'ActivityRelation/:id',
        update: 'ActivityRelation/Update',
    };

    constructor(private readonly httpService: HttpService) {}

    getOverview(
        sorting: TableSortingModel,
        pagination: TablePaginationModel,
        activityId: number,
        sortingThen?: TableSortingModel[],
        filter?: TableFilterModel[],
    ): Observable<TableDataModel<ActivityRelationTableItemModel>> {
        const paginationParams = parse(pagination, 'pagination');
        const sortingParams = parse(sorting, 'sorting');
        const activityIdParam = `activityId=${activityId}`;
        const sortingThenParams = sortingThen ? `&${parse(sortingThen, 'ThenBy')}` : '';
        const sortingFilter = filter ? `&${parse(filter, 'Filters')}` : '';

        return this.httpService.get<TableDataModel<ActivityRelationTableItemModel>>(
            this.endpoints.overview +
                `?${paginationParams}&${sortingParams}&${activityIdParam}${sortingThenParams}${sortingFilter}`,
        );
    }

    createReservation(activityId: number): Observable<ActivityRelationTableItemModel> {
        return this.httpService.post<ActivityRelationTableItemModel>(this.endpoints.createReservation, { activityId });
    }

    delete(id: number): Observable<void> {
        return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
    }

    deleteReservation(id: number): Observable<void> {
        return this.httpService.delete<void>(this.endpoints.deleteRelation.replace(':id', id.toString()));
    }

    create(request: ActivityRelationCreateModel): Observable<ActivityRelationUpdateModel> {
        return this.httpService.post<ActivityRelationUpdateModel>(this.endpoints.create, request);
    }

    update(request: ActivityRelationUpdateModel): Observable<ActivityRelationUpdateModel> {
        return this.httpService.post<ActivityRelationUpdateModel>(this.endpoints.update, request);
    }

    getActivityRelation(id: number): Observable<ActivityRelationUpdateModel> {
        return this.httpService.get<ActivityRelationUpdateModel>(
            this.endpoints.getActivityRelation.replace(':id', id.toString()),
        );
    }
}
