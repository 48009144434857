import { Injectable } from '@angular/core';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as ActivityDownloadActions from '@app/modules/activity-download/actions/activity-download-api.actions';
import * as ActivityDownload from '@app/modules/activity-download/reducers';
import { ActivityAssetService } from '@app/modules/activity-download/services/activity-asset.service';
import { ActivityDownloadService } from '@app/modules/activity-download/services/activity-download.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class ActivityDownloadEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly activityDownloadService: ActivityDownloadService,
        private readonly activityAssetService: ActivityAssetService,
        private readonly messageService: NzMessageService,
        private readonly translate: TranslateService,
        private readonly store: Store<ActivityDownload.State>,
    ) {}

    /**
     * Get effects
     */
    loadActivityDownloads$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityDownloadActions.GetOverview),
            concatLatestFrom(() => [
                this.store.select(ActivityDownload.selectActivityDownloadOverviewPagination),
                this.store.select(ActivityDownload.selectActivityDownloadOverviewSorting),
                this.store.select(ActivityDownload.selectChosenActivityId),
                this.store.select(ActivityDownload.selectActivityDownloadOverviewFilter),
            ]),
            exhaustMap(([, pagination, sorting, activityId, filter]) =>
                this.activityDownloadService.getOverview(sorting, pagination, activityId, filter).pipe(
                    map((response) => ActivityDownloadActions.GetOverviewSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityDownloadActions.GetOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadActivityDownloadsFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityDownloadActions.FilterOverview),
            map(() => ActivityDownloadActions.GetOverview()),
        ),
    );

    uploadActivityDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityDownloadActions.Upload),
            switchMap((action) =>
                this.activityDownloadService.upload(action).pipe(
                    map((response) => ActivityDownloadActions.UploadSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityDownloadActions.UploadError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    uploadActivityDownloadSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityDownloadActions.UploadSuccess),
                tap((response) => {
                    if (response.id !== null) {
                        this.messageService.success(this.translate.instant('activity-download.notification.upload-success'));
                    } else {
                        this.messageService.success(this.translate.instant('activity-download.notification.save-success'));
                    }
                }),
            ),
        { dispatch: false },
    );

    uploadActivityDownloadError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityDownloadActions.UploadError),
                tap(() => {
                    this.messageService.error(this.translate.instant('activity-download.notification.error'));
                }),
            ),
        { dispatch: false },
    );

    loadActivityDownloadsUploadSuccessChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityDownloadActions.UploadSuccess),
            map(() => ActivityDownloadActions.GetOverview()),
        ),
    );

    deleteActivityDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityDownloadActions.Delete),
            switchMap((action) =>
                this.activityDownloadService.delete(action.id).pipe(
                    map(() => ActivityDownloadActions.DeleteSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityDownloadActions.DeleteError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    deleteActivityDownloadSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityDownloadActions.DeleteSuccess),
                tap(() => {
                    this.messageService.success(this.translate.instant('notification.delete-success'));
                }),
            ),
        { dispatch: false },
    );

    loadActivityDownloadsDeleteActivityDownloadSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityDownloadActions.DeleteSuccess),
            map(() => ActivityDownloadActions.GetOverview()),
        ),
    );

    getDefaultAssets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityDownloadActions.GetDefaultAssets),
            switchMap(() =>
                this.activityAssetService.getDefault().pipe(
                    map((response) => ActivityDownloadActions.GetDefaultAssetsSuccess({ response })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityDownloadActions.GetDefaultAssetsError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    getAssets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityDownloadActions.GetAssets),
            switchMap((action) =>
                this.activityAssetService.get(action.activityId).pipe(
                    map((response) => ActivityDownloadActions.GetAssetsSuccess({ response })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityDownloadActions.GetAssetsError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );
}
