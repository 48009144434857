import * as RelationActivityApiActions from '@app/modules/relation-activity/actions/relation-activity-api.actions';
import { ActivityRelationUpdateModel } from '@app/modules/relation-activity/models/activity-relation-update.model';
import { RelationActivityTableItemModel } from '@app/modules/relation-activity/models/relation-activity-table.model';
import { ActivityAvailableParticipantsEnum } from '@app/shared/enums/activity-available-participants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
    activities: new TableOverviewModel<RelationActivityTableItemModel>('Role'),
    activityList: [],
    relationId: 0,
    loading: false,
    errors: {},
    relationActivity: {} as ActivityRelationUpdateModel,
};

export interface State {
    activities: TableStateModel<RelationActivityTableItemModel>;
    activityList: KeyValueModel[];
    relationId: number;
    loading: boolean;
    errors: { [key: string]: string[] };
    relationActivity: ActivityRelationUpdateModel;
}

export const reducer = createReducer(
    initialState,

    // normal overview
    on(RelationActivityApiActions.GetOverview, (state) => ({
        ...state,
        activities: { ...state.activities, loading: true },
    })),
    on(RelationActivityApiActions.GetOverviewSuccess, (state, response) => ({
        ...state,
        activities: {
            ...state.activities,
            ...response,
            loading: false,
        },
    })),
    on(RelationActivityApiActions.GetOverviewError, (state) => ({
        ...state,
        activities: { ...state.activities, loading: false },
    })),

    on(RelationActivityApiActions.FilterOverview, (state, { relationId, sorting, pagination, thenBy, filter }) => ({
        ...state,
        relationId,
        activities: {
            ...state.activities,
            sorting: applySortingOrDefault(initialState.activities, sorting),
            pagination: {
                ...state.activities.pagination,
                page: pagination.page,
                pageSize: pagination.pageSize,
            },
            filter,
            thenBy,
        },
    })),

    // create
    on(RelationActivityApiActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(RelationActivityApiActions.CreateSuccess, (state) => ({ ...state, loading: false })),
    on(RelationActivityApiActions.CreateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    // update
    on(RelationActivityApiActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(RelationActivityApiActions.UpdateSuccess, (state) => ({ ...state, loading: false })),
    on(RelationActivityApiActions.UpdateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    // general
    on(RelationActivityApiActions.ResetOverview, () => ({ ...initialState })),
    on(RelationActivityApiActions.ResetCreate, (state) => ({
        ...initialState,
        activities: state.activities,
        relationId: state.relationId,
    })),

    // get
    on(RelationActivityApiActions.GetActivityRelation, (state) => ({ ...state, loading: true })),
    on(RelationActivityApiActions.GetActivityRelationSuccess, (state, activityRelation) => ({
        ...state,
        relationActivity: activityRelation.response,
        loading: false,
    })),
    on(RelationActivityApiActions.GetActivityRelationError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),
);

export const getLoading = (state: State) => state.activities.loading;
export const getList = (state: State) => state.activities.list;
export const getPagination = (state: State) => state.activities.pagination;
export const getSorting = (state: State) => state.activities.sorting;
export const getRelationId = (state: State) => state.relationId;
export const getSortingThen = (state: State) => state.activities.thenBy;
export const getFilter = (state: State) => state.activities.filter;
export const getOverview = (state: State) => state.activities;
export const getCreateErrors = (state: State) => state.errors;
export const getCreateLoading = (state: State) => state.loading;
export const getRelationActivity = (state: State) => state.relationActivity;
export const getCatchupParticipants = (state: State) => state.activities.list.filter(x => x.role === ActivityAvailableParticipantsEnum.CatchUp);
export const getNonCatchupParticipants = (state: State) => state.activities.list.filter(x => x.role !== ActivityAvailableParticipantsEnum.CatchUp);