<div>
    <div *nzModalTitle>
        {{ 'activity-download.modal.upload.title' | translate }}
    </div>
    <form nz-form [formGroup]="formGroup">
        <section class="form-section">
            <header>
                <h5 class="form-section__title">
                    {{ 'activity-download.modal.upload.header.upload' | translate }}
                </h5>
            </header>
            <div nz-row>     
                <nz-form-control [nzSpan]="24" *ngIf="!formGroup.controls.file.value">
                    <nz-upload nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUpload">
                        <i class="fa-regular fa-upload"></i> {{ 'common.upload' | translate }}
                    </nz-upload>
                    
                    <app-input-validation [control]="formGroup.controls.file" [field]="'file'" [errors]="errors"/>
                </nz-form-control>

                <nz-list nzBordered *ngIf="!!formGroup.controls.file.value" class="w-100 mt-md">
                    <nz-list-item>
                        {{ formGroup.controls.file.value.name }}
        
                        <button nz-button nzType="text" class="error" (click)="removeFile()">
                            <i class="fa-regular fa-trash-can"></i>
                            {{ "common.delete" | translate }}
                        </button>
                    </nz-list-item>
                </nz-list>
            </div>
        </section>
        <section class="form-section">
            <header>
                <h5 class="form-section__title">
                    {{ 'activity-download.modal.upload.header.select' | translate }}
                </h5>
            </header>
            <div class="form-section__content">
                <em>{{'activity-download.modal.upload.body.selection-info' | translate }}</em>
                <nz-checkbox-wrapper formArrayName="assets" class="assets-checkbox-wrapper pe-sm">
                    <div *ngFor="let asset of formGroup.controls.assets.controls; index as assetIndex" nz-row>
                        <div [formGroupName]="assetIndex">
                            <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                            <label
                                nz-checkbox
                                formControlName="checked">
                                {{ asset.controls.label.value }}
                            </label>
                        </div>
                    </div>
                </nz-checkbox-wrapper>
                <app-input-validation [control]="formGroup.controls.assets" [field]="'AssetIds'" [errors]="errors"/>
            </div>
        </section>
    </form>

    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="destroyModal()">{{ 'common.cancel' | translate }}</button>
        <button nz-button nzType="primary" (click)="submit()">{{ 'activity-download.modal.upload.footer.save' | translate }}</button>
    </div>
</div>
