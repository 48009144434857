import { Injectable } from '@angular/core';
import { ErrorResponse } from '@app/core/models/error-response.model';
import { CertificateService } from '@app/modules/certificate/services/certificate.service';
import * as CertificateActions from '@app/modules/certificate/actions/certificate-api.actions';
import * as Certificate from '@app/modules/certificate/reducers';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CertificateEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly certificateService: CertificateService,
        private readonly store: Store<Certificate.State>,
        private readonly messageService: NzMessageService,
        private readonly translate: TranslateService
    ) {}

    /**
     * Get effects
     */
    loadCertificates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CertificateActions.GetOverview),
            concatLatestFrom(() => [
                this.store.select(Certificate.selectCertificateOverviewFilter),
                this.store.select(Certificate.selectCertificateOverviewPagination),
                this.store.select(Certificate.selectCertificateOverviewSorting),
                this.store.select(Certificate.selectActivityId),
                this.store.select(Certificate.selectRelationId),
            ]),
            exhaustMap(([, filter, pagination, sorting, activityId, relationId]) =>
                this.certificateService.getOverview(sorting, pagination, filter, activityId, relationId).pipe(
                    map((response) => CertificateActions.GetOverviewSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(CertificateActions.GetOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadCertificatesOnDeleteSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CertificateActions.DeleteSuccess),
            map(() => CertificateActions.GetOverview()),
        ));

    loadCertificatesFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CertificateActions.FilterOverview),
            map(() => CertificateActions.GetOverview()),
        ),
    );

    deleteCertificate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CertificateActions.Delete),
            exhaustMap(({ id }) =>
                this.certificateService.delete(id).pipe(
                    map(() => CertificateActions.DeleteSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(CertificateActions.DeleteError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    deleteCertificateSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CertificateActions.DeleteSuccess),
                tap(() => {
                    this.messageService.success(this.translate.instant('notification.delete-success'));
                }),
            ),
        { dispatch: false },
    );
}
