<div class="page-header">
    <a routerLink="/activiteiten/overzicht" class="page-header__back">
        <i class="fa-regular fa-chevron-left"></i>
    </a>
    <h1 class="page-header__title">{{ 'activity.title' | translate }}</h1>
    <div class="page-header__actions">
        <button [routerLink]="['/activiteiten/bewerken', (activity$ | async)?.id]" nz-button nzType="primary">
            {{ 'activity.detail.edit-title' | translate }}
            <i class="fa-regular fa-pen"></i>
        </button>
    </div>
</div>

<div nz-row class="mb-xs">
    <div nz-col nzSpan="24">
        <app-activity-view-card [activity]="(activity$ | async)!" [loading]="loading$ | async" />
    </div>
</div>
<div nz-row>
    <div nz-col [nzSpan]="this.tabSize">
        <div class="tab-container">
            <nz-tabset nzType="card" (nzSelectChange)="selectedTab($event)">
                <nz-tab [nzTitle]="'activity.header.details' | translate">
                    <app-activity-view-info
                        [activity]="(activity$ | async)!"
                        [participants]="availableParticipants$ | async"
                        (availableParticipantsUpdated)="updateAvailableParticipants($event)"
                        [errors]="errors$ | async"
                        [loading]="loading$ | async" />
                </nz-tab>

                <nz-tab [nzTitle]="'activity.header.participants' | translate">
                    <app-activity-relation-overview
                        [data]="(activityRelations$ | async)!"
                        [activity]="(activity$ | async)!"
                        [dataWaitlist]="(activityRelationsWaitlist$ | async)!"
                        [backgroundList]="(backgroundList$ | async)!"
                        (addReservation)="addReservation()"
                        (delete)="deleteParticipant($event)"
                        [relations$]="relationList$"
                        (search)="searchRelation($event)"
                        (create)="createActivityRelation($event)"
                        (getRelation)="getRelation($event)"
                        [activityRelation]="(activityRelation$ | async)!"
                        (resetOverview)="reset()" />
                </nz-tab>

                <nz-tab [nzTitle]="'activity.header.downloads' | translate">
                    <app-activity-download-overview
                        [data]="(activityDownloads$ | async)!"
                        [activity]="(activity$ | async)!"
                        [assets]="(activityAssets$ | async)!"
                        [defaultAssets]="(activityDefaultAssets$ | async)!"
                        [loading$]="activityDownloadLoading$"
                        [errors$]="activityDownloadErrors$"
                        (upload)="uploadActivityDownload($event)"
                        (delete)="deleteActivityDownload($event)" />
                </nz-tab>

                <nz-tab [nzTitle]="'activity.header.certificates' | translate">
                    <app-certificate-overview [data]="certificates$ | async" [showRelationName]="true" (delete)="deleteCertificate($event)"></app-certificate-overview>
                </nz-tab>
            </nz-tabset>
        </div>
    </div>

    <div nz-col [nzFlex]="2" [nzSpan]="6" *ngIf="this.noteSidebarEnabled">
        <app-notes-sidebar
            [note$]="note$"
            [errors$]="noteErrors$"
            [loading$]="noteLoading$"
            [notes]="(notes$ | async)!"
            (createNote)="createNote($event)"
            (setNoteImportant)="setNoteImportant($event)"
            (doEditNote)="editNote($event)"
            (doDeleteNote)="deleteNote($event)"></app-notes-sidebar>
    </div>
</div>
