import { ErrorResponse } from "@app/core/models/error-response.model";
import { CountryModel } from "@app/modules/countries/models";
import { RelationCreateModel, RelationTableItemModel, RelationUpdateModel } from "@app/modules/relation/models";
import { BillingInformationModel, TableDataModel } from "@app/shared/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const Get = createAction("[Relation] Get", props<{ id: number }>());
export const GetError = createAction("[Relation] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Relation] GetSuccess", props<RelationUpdateModel>());

export const GetOverview = createAction("[Relation] GetOverview");
export const GetOverviewError = createAction("[Relation] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Relation] GetOverviewSuccess", props<TableDataModel<RelationTableItemModel>>());

export const FilterOverview = createAction("[Relation] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[Relation] Clear");

export const ChangeActive = createAction("[Relation] ChangeActive", props<{ id: number }>());
export const ChangeActiveError = createAction("[Relation] ChangeActiveError", props<{ response: ErrorResponse }>());
export const ChangeActiveSuccess = createAction("[Relation] ChangeActiveSuccess", props<RelationUpdateModel>());

export const Create = createAction("[Relation] Create", props<RelationCreateModel>());
export const CreateError = createAction("[Relation] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Relation] CreateSuccess", props<RelationUpdateModel>());

export const Update = createAction("[Relation] Update", props<RelationUpdateModel>());
export const UpdateError = createAction("[Relation] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Relation] UpdateSuccess", props<RelationUpdateModel>());

export const GetRelationTypes = createAction("[Relation] GetRelationTypes");
export const GetRelationTypesError = createAction("[Relation] GetRelationTypesError", props<{ response: ErrorResponse }>());
export const GetRelationTypesSuccess = createAction("[Relation] GetRelationTypesSuccess", props<{ relationTypes: KeyValueModel[]}>());

export const GetRelationBillingInformation = createAction("[Relation] GetRelationBillingInformation", props<{ id: number }>());
export const GetRelationBillingInformationError = createAction("[Relation] GetRelationBillingInformationError", props<{ response: ErrorResponse }>());
export const GetRelationBillingInformationSuccess = createAction("[Relation] GetRelationBillingInformationSuccess", props<{ relationBillingInformation: BillingInformationModel[]}>());

export const GetCountries = createAction("[Relation] GetCountries");
export const GetCountriesError = createAction("[Relation] GetCountriesError", props<{ response: ErrorResponse }>());
export const GetCountriesSuccess = createAction("[Relation] GetCountriesSuccess", props<{ countries: CountryModel[]}>());

export const GetByEmail = createAction("[Relation] GetByEmail", props<{ email: string }>());
export const GetByEmailError = createAction("[Relation] GetByEmailError", props<{ response: ErrorResponse }>());
export const GetByEmailSuccess = createAction("[Relation] GetByEmailSuccess", props<RelationUpdateModel>());

export const ResendWelcomeMail = createAction("[Relation] ResendWelcomeMail");
export const ResendWelcomeMailError = createAction("[Relation] ResendWelcomeMailError", props<{ response: ErrorResponse }>());
export const ResendWelcomeMailSuccess = createAction("[Relation] ResendWelcomeMailSuccess");

export const LoginAsRelation = createAction("[Relation] LoginAsRelation");
export const LoginAsRelationError = createAction("[Relation] LoginAsRelationError", props<{ response: ErrorResponse }>());
export const LoginAsRelationSuccess = createAction("[Relation] LoginAsRelationSuccess", props<{ link: string }>());

export const Delete = createAction("[Relation] Delete");
export const DeleteError = createAction("[Relation] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Relation] DeleteSuccess");

export const GetActivities = createAction('[Activity] GetRelations',props<{ searchQuery: string; skipCount: number; activityId: number | null }>(),);
export const GetActivitiesError = createAction('[Activity] GetRelationsError', props<{ response: ErrorResponse }>());
export const GetActivitiesSuccess = createAction('[Activity] GetRelationsSuccess',props<{ activities: KeyValueModel[] }>());