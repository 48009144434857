import { Component, EventEmitter, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivityRelationCreateModel } from '@app/modules/activity-relation/models/activity-relation-create.model';
import { ActivityRelationUpdateModel } from '@app/modules/activity-relation/models/activity-relation-update.model';
import { ParticipantStatus, ParticipantStatusLabels } from '@app/modules/activity/enums/participant-status.enum';
import { PaymentStatus, PaymentStatusLabels } from '@app/modules/activity/enums/payment-status.enum';
import { RelationUpdateModel } from '@app/modules/relation/models';
import {
    ActivityAvailableParticipantsEnum,
    ActivityAvailableParticipantsLabels,
} from '@app/shared/enums/activity-available-participants';
import { ApplicationType, ApplicationTypeLabelsMapping } from '@app/shared/enums/application-type.enum';
import { PaymentType, PaymentTypeLabelsMapping } from '@app/shared/enums/payment-type.enum';
import { ResultStatus, ResultStatusLabels } from '@app/shared/enums/result-status.enum';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { isFormValid, processBackendValidatorsErrors } from '@app/shared/utilities/validation.utilities';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { filter, Observable } from 'rxjs';

@Component({
    selector: 'app-relation-activity-create-modal',
    templateUrl: './relation-activity-create-modal.component.html',
})
export class RelationActivityCreateModalComponent implements OnInit {
    errors: { [key: string]: string[] } = {};
    readonly nzModalData = inject(NZ_MODAL_DATA);

    activities$: Observable<KeyValueModel[]> = this.nzModalData.activities$;
    backgroundList: KeyValueModel[] = this.nzModalData.backgroundList;

    searchEmmiter: EventEmitter<{ searchQuery: string; skipCount: number }> = this.nzModalData.searchEmitter;
    create: EventEmitter<ActivityRelationCreateModel | ActivityRelationUpdateModel> = this.nzModalData.createEmitter;

    activityRelation: ActivityRelationUpdateModel = this.nzModalData.relationActivity;
    relation: RelationUpdateModel = this.nzModalData.relation;
    errors$: Observable<{ [key: string]: string[] }> = this.nzModalData.errors$;

    startDate: Date | null = null;
    endDate: Date | null = null;

    update: boolean;

    searchEmitterSearch(event: { searchQuery: string; skipCount: number }) {
        this.searchEmmiter.emit(event);
    }

    formGroup = new FormGroup({
        specificDate: new FormControl<number | null>({
            value: null,
            disabled: this.endDate === null || this.startDate === null,
        }),
        role: new FormControl<ActivityAvailableParticipantsEnum>(
            ActivityAvailableParticipantsEnum.Student,
            Validators.required,
        ),
        applicationType: new FormControl<ApplicationType>(ApplicationType.Normal, Validators.required),
        status: new FormControl<ParticipantStatus>(ParticipantStatus.Registered, Validators.required),
        paymentStatus: new FormControl<PaymentStatus>(PaymentStatus.Open, Validators.required),
        paymentType: new FormControl<PaymentType>(PaymentType.None, Validators.required),
        employerPays: new FormControl<boolean>(false),
        invoiceNumber: new FormControl<string>('', Validators.maxLength(20)),
        result: new FormControl<ResultStatus>(ResultStatus.Open, Validators.required),
        intake: new FormControl<boolean>(false),
        message: new FormControl<string>(''),
        allowVideo: new FormControl<boolean>(false),
        backgrounds: new UntypedFormGroup({}),
        activityId: new FormControl<number>(null, Validators.required),
    });

    datesArray: KeyValueModel[] = [];

    constructor(private readonly modal: NzModalRef) {
        this.errors$
            .pipe(
                takeUntilDestroyed(),
                filter((x) => Object.keys(x).length > 0),
            )
            .subscribe((x) => {
                processBackendValidatorsErrors(x, this.formGroup);
            });
    }

    ngOnInit(): void {
        this.update = this.activityRelation?.id != null;

        this.backgroundList.forEach((x) =>
            this.formGroup.controls.backgrounds.addControl(
                String(x.key),
                new FormControl<boolean>(
                    this.activityRelation ? this.activityRelation.backgroundIds.includes(x.key) : false,
                ),
            ),
        );
        if (this.update) {
            this.formGroup.patchValue({
                activityId: this.activityRelation.activityId,
                specificDate: this.activityRelation.specificDate,
                role: this.activityRelation.role ?? ActivityAvailableParticipantsEnum.Student,
                applicationType: this.activityRelation.applicationType ?? this.applicationTypes.Normal,
                status: this.activityRelation.status ?? ParticipantStatus.Registered,
                paymentStatus: this.activityRelation.paymentStatus ?? PaymentStatus.Open,
                paymentType: this.activityRelation.PaymentType ?? PaymentType.None,
                employerPays: this.activityRelation.employeePays,
                invoiceNumber: this.activityRelation.invoiceNumber,
                result: this.activityRelation.result ?? this.resultStatus.Open,
                intake: this.activityRelation.intake,
                message: this.activityRelation.message,
                allowVideo: this.activityRelation.allowVideo,
            });
        }
    }

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    draggingEnabled = false;

    roleTypeLabels = ActivityAvailableParticipantsEnum;
    roleTypeMapping = ActivityAvailableParticipantsLabels;

    applicationTypes = ApplicationType;
    applicationTypeMapping = ApplicationTypeLabelsMapping;

    paymentStatus = PaymentStatus;
    paymentStatusMapping = PaymentStatusLabels;

    paymentTypes = PaymentType;
    paymentTypeMapping = PaymentTypeLabelsMapping;

    resultStatus = ResultStatus;
    resultStatusMapping = ResultStatusLabels;

    participantStatus = ParticipantStatus;
    participantStatusMapping = ParticipantStatusLabels;

    destroyModal(): void {
        this.modal.destroy();
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
            this.modal.triggerOk();
        }
    }

    private submitCreate() {
        const backgroundIdList = Object.entries(this.formGroup.controls.backgrounds.controls)
            .filter((key) => key[1].value)
            .map((key) => Number(key[0]));
        this.create.emit({
            activityId: this.formGroup.controls.activityId.value,
            relationId: this.relation.id,
            specificDate: this.formGroup.controls.specificDate.value,
            role: this.formGroup.controls.role.value,
            applicationType: this.formGroup.controls.applicationType.value,
            status: this.formGroup.controls.status.value,
            result: this.formGroup.controls.result.value,
            paymentStatus: this.formGroup.controls.paymentStatus.value,
            PaymentType: this.formGroup.controls.paymentType.value,
            employeePays: this.formGroup.controls.employerPays.value ?? false,
            invoiceNumber: this.formGroup.controls.invoiceNumber.value ?? '',
            backgroundIds: backgroundIdList,
            intake: this.formGroup.controls.intake.value ?? false,
            message: this.formGroup.controls.message.value ?? '',
            allowVideo: this.formGroup.controls.allowVideo.value ?? false,
        });
    }

    private submitUpdate() {
        const backgroundIdList = Object.entries(this.formGroup.controls.backgrounds.controls)
            .filter((key) => key[1].value)
            .map((key) => Number(key[0]));
        this.create.emit({
            id: this.activityRelation.id,
            activityId: this.formGroup.controls.activityId.value,
            relationId: this.relation.id,
            specificDate: this.formGroup.controls.specificDate.value,
            role: this.formGroup.controls.role.value,
            applicationType: this.formGroup.controls.applicationType.value,
            status: this.formGroup.controls.status.value,
            result: this.formGroup.controls.result.value,
            paymentStatus: this.formGroup.controls.paymentStatus.value,
            PaymentType: this.formGroup.controls.paymentType.value,
            employeePays: this.formGroup.controls.employerPays.value ?? false,
            invoiceNumber: this.formGroup.controls.invoiceNumber.value ?? '',
            backgroundIds: backgroundIdList,
            intake: this.formGroup.controls.intake.value ?? false,
            message: this.formGroup.controls.message.value ?? '',
            allowVideo: this.formGroup.controls.allowVideo.value ?? false,
        });
    }
}
