<div nz-row>
    <div nz-col>
        <div class="page-header">
            <div class="page-header__actions">
                <button nz-button nzType="primary" claimDirective [claims]="[
                            {
                                claimType: ClaimTypes.ActivityDownload,
                                claimValues: [ClaimValues.Read, ClaimValues.Create]
                            }
                        ]" (click)="showUploadModal()">
                    {{ 'activity-download.detail.select-upload-file' | translate }}
                    <i class="fa-solid fa-plus"></i>
                </button>
            </div>
        </div>

        <nz-table #overviewTable [nzData]="tableData" [nzLoading]="tableLoading" [nzFrontPagination]="false" nzTableLayout="fixed">
            <thead>
                <tr>
                    <th>
                        {{ 'activity-download.fields.name' | translate }}
                    </th>
                    <th nzWidth="120px" claimDirective [claims]="[
                    {
                        claimType: ClaimTypes.ActivityDownload,
                        claimValues: [ClaimValues.Read, ClaimValues.Delete]
                    }
                ]"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let row of data.list; let i = index">
                    <tr>
                        <td>
                            <a [href]="row.url" target="_blank">
                                <i class="fa-solid fa-download"></i>&nbsp;{{ row.name }}
                            </a>
                        </td>
                        <td>
                            <div class="table-actions">
                                <button claimDirective [claims]="[
                                {
                                    claimType: ClaimTypes.ActivityDownload,
                                    claimValues: [ClaimValues.Delete]
                                }
                            ]" nz-button nzDanger nzType="text" nzShape="circle" nz-popconfirm nzOkDanger
                                    [nzPopconfirmTitle]="'notification.delete' | translate"
                                    [nzOkText]="'common.delete' | translate"
                                    [nzCancelText]="'common.cancel' | translate" [nzIcon]="templateIconDelete"
                                    nzPopconfirmPlacement="left" (nzOnConfirm)="delete.emit({id: row.id })">
                                    <i class="fa-regular fa-trash-can"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngFor="let row of assets; let i = index">
                    <tr>
                        <td>
                            <a [href]="row.url" target="_blank">
                                <i class="fa-solid fa-download"></i>&nbsp;{{ row.name }}
                            </a>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
        <ng-template #templateIconDelete>
            <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
        </ng-template>

    </div>
</div>