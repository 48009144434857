import * as BackgroundApiActions from "@app/modules/background/actions/background-api.actions";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { createReducer, on } from "@ngrx/store";

const initialState: State = {
    loading: false,
    backgroundList: [],
};

export interface State {
    loading: boolean;
    backgroundList: KeyValueModel[]
 }

export const reducer = createReducer(initialState,
    on(BackgroundApiActions.GetBackgroundListSuccess, (state, response) => ({
        ...state,
        backgroundList: response.response,
    })),

    on(BackgroundApiActions.Clear,  () => ({ ...initialState }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.backgroundList;
