import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivityDownloadUploadModalComponent } from '@app/modules/activity-download/components/activity-download-upload-modal/activity-download-upload-modal.component';
import { ActivityAssetModel } from '@app/modules/activity-download/models/activity-asset.model';
import { ActivityDownloadUploadModel } from '@app/modules/activity-download/models/activity-download-upload.model';
import { ActivityDownloadModel } from '@app/modules/activity-download/models/activity-download.model';
import { ActivityUpdateModel } from '@app/modules/activity/models/activity-update.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, filter } from 'rxjs';

@Component({
    selector: 'app-activity-download-overview',
    templateUrl: './activity-download-overview.component.html',
    styleUrls: ['./activity-download-overview.component.less'],
})
export class ActivityDownloadOverviewComponent {
    @Input({ required: true }) data: TableStateModel<ActivityDownloadModel>;
    @Input({ required: true }) activity: ActivityUpdateModel;

    @Input({ required: true }) assets: ActivityAssetModel[];
    @Input({ required: true }) defaultAssets: ActivityAssetModel[];

    @Input({ required: true }) loading$: Observable<boolean>;
    @Input({ required: true }) errors$: Observable<{ [key: string]: string[] }>;

    @Output() delete = new EventEmitter<{ id: number }>();

    @Output() upload = new EventEmitter<ActivityDownloadUploadModel>();
    @Output() resetOverview = new EventEmitter<void>();

    get tableData() {
        return (this.data?.list || []).concat(this.assets);
    }

    get tableLoading() {
        return this.data === null ? true : this.data.loading;
    }

    destroyRef = inject(DestroyRef);
    modalService = inject(NzModalService);

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    showUploadModal() {
        const modal = this.modalService.create({
            nzContent: ActivityDownloadUploadModalComponent,
            nzWidth: '60%',
            nzMaskClosable: false,
            nzData: {
                assets: this.assets,
                defaultAssets: this.defaultAssets,
                uploadEmitter: this.upload,
                activityId: this.activity.id,
                errors$: this.errors$,
            },
            nzOnOk: () =>
                new Promise((resolve, reject) => {
                    this.loading$
                        .pipe(
                            takeUntilDestroyed(this.destroyRef),
                            filter((v) => !v),
                        )
                        .subscribe(() => {
                            this.errors$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((error) => {
                                Object.keys(error).length === 0 ? resolve('success') : reject('error');
                            });
                        });
                }),
        });
        modal.afterClose.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.resetOverview.emit());
    }
}
