import { AbstractControlOptions, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn } from "@angular/forms";

export class CheckboxFormGroup extends FormGroup {
    constructor(
        checkbox: { label: string, value: number, checked: boolean }, 
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, 
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super({
            label: new FormControl<string>(checkbox.label),
            value: new FormControl<number>(checkbox.value),
            checked: new FormControl<boolean>(checkbox.checked),
        }, validatorOrOpts, asyncValidator);
    }

    override controls: {
        label: FormControl<string>,
        value: FormControl<number>,
        checked: FormControl<boolean>,
    }
}

export const getCheckedValues = (formArray: FormArray<CheckboxFormGroup>): number[] => {
    return formArray.controls.filter(x => x.controls.checked.value).map(x => x.controls.value.value);
}