import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as ActivityDownloadOverview from './activity-download-overview.reducer';

export const activityDownloadFeatureKey = 'activityDownload'

export interface ActivityDownloadState {
    activityDownloadOverview: ActivityDownloadOverview.State
}

export interface State {
    activityDownload: ActivityDownloadState
}

const map: ActionReducerMap<ActivityDownloadState, Action> = {
    activityDownloadOverview: ActivityDownloadOverview.reducer
}

export const reducers = (state: ActivityDownloadState, action: Action) => combineReducers(map)(state, action);

const selectActivityDownloadState = createFeatureSelector<ActivityDownloadState>(activityDownloadFeatureKey);

export const selectState = createSelector(selectActivityDownloadState, state => state)
export const selectActivityDownloadOverviewState = createSelector(selectState, state => state.activityDownloadOverview)

export const selectActivityDownloadOverviewStateList = createSelector(selectState, state => state.activityDownloadOverview.downloads)

export const selectActivityDownloadOverview = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getOverview)
export const selectActivityDownloadOverviewList = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getList)
export const selectActivityDownloadOverviewPagination = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getPagination)
export const selectActivityDownloadOverviewSorting = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getSorting)
export const selectActivityDownloadOverviewFilter = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getFilter)
export const selectActivityDownloadOverviewLoading = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getOverviewLoading)
export const selectChosenActivityId = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getActivityId)

export const selectAssets = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getAssets)
export const selectDefaultAssets = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getDefaultAssets)
export const selectLoading = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getLoading);
export const selectErrors = createSelector(selectActivityDownloadOverviewState, ActivityDownloadOverview.getErrors);