import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivityUpdateModel } from '@app/modules/activity/models';
import { ActivityAvailableParticipantsModel } from '@app/modules/activity/models/activity-available-participants.model';
import { UpdateAvailableParticipantsModel } from '@app/modules/activity/models/update-activity-available-participants.model';
import { ActivityAvailableParticipantsEnum } from '@app/shared/enums/activity-available-participants';
import { DatePickerType } from '@app/modules/activity/models/activity-create.model';
import { formatDate } from '@angular/common';
import { ParticipantStatusLabels } from '@app/modules/activity/enums/participant-status.enum';

@Component({
    selector: 'app-activity-view-info',
    templateUrl: './activity-view-info.component.html',
    styleUrls: ['./activity-view-info.component.less'],
})
export class ActivityInfoViewComponent {
    readonly?: boolean;

    @Input({ required: true }) activity!: ActivityUpdateModel;
    @Input() errors: { [key: string]: string[] } = {};
    @Input() loading = false;
    @Input({ required: true}) participants: ActivityAvailableParticipantsModel | null = {} as ActivityAvailableParticipantsModel;
    @Output() availableParticipantsUpdated = new EventEmitter<UpdateAvailableParticipantsModel>();

    roleType = ActivityAvailableParticipantsEnum;
    statusLabel = ParticipantStatusLabels;

    totalAttachments(): number {
        let total = 0;
        total += (this.activity?.firstAttachment ? 1 : 0)
        total += (this.activity?.secondAttachment ? 1 : 0)
        return total;
    }

    IncrementAvailablePlaces(id: number, roleType: number) {
        this.availableParticipantsUpdated.emit({ id, action: "increment", roleType})
    }

    DecrementAvailablePlaces(id: number, roleType: number) {
        this.availableParticipantsUpdated.emit({ id, action: "decrement", roleType})
    }

    parseTimeToDate(timeString: Date | null | undefined): DatePickerType {
        return timeString
            ? new Date(`${formatDate(new Date(),'YYYY-MM-dd', 'nl-NL')} ${timeString}`)
            : null;
    }
}
