<nz-table
    #overviewTable
    [nzData]="data.list"
    [nzPageIndex]="data.pagination.page"
    [nzPageSize]="data.pagination.pageSize"
    [nzTotal]="data.pagination.totalItems ?? 0"
    [nzLoading]="data.loading"
    [nzFrontPagination]="false"
    [nzShowPagination]="!draggingEnabled"
    (nzQueryParams)="onQueryParamsChange.emit($event)"
    nzTableLayout="fixed">
    <thead>
        <tr>
            <th nzColumnKey="online" [nzSortFn]="true">
                {{ 'activity.fields.online' | translate }}
            </th>
            <th nzColumnKey="ActivityType.Name" [nzSortFn]="true" [nzSortOrder]="'ascend'">
                {{ 'activity.fields.type' | translate }}
            </th>
            <th nzColumnKey="name" [nzSortFn]="true">
                {{ 'activity.fields.name' | translate }}
            </th>
            <th nzColumnKey="code" [nzSortFn]="true">
                {{ 'activity.fields.code' | translate }}
            </th>
            <th nzColumnKey="trainer">
                {{ 'activity.fields.trainer' | translate }}
            </th>
            <th nzColumnKey="FirstAttachment" [nzSortFn]="true">
                {{ 'activity.fields.attachments' | translate }}
            </th>
            <th nzColumnKey="ActivityLocation.CalendarName" [nzSortFn]="true">
                {{ 'activity.fields.calender-name' | translate }}
            </th>
            <th nzColumnKey="StartDate" [nzSortFn]="true">
                {{ 'activity.fields.start' | translate }}
            </th>
            <th nzColumnKey="EndDate" [nzSortFn]="true">
                {{ 'activity.fields.end' | translate }}
            </th>
            <th nzColumnKey="VisibleFromDate" [nzSortFn]="true">
                {{ 'activity.fields.online-from' | translate }}
            </th>
            <th nzColumnKey="VisibleUntilDate" [nzSortFn]="true">
                {{ 'activity.fields.online-untill' | translate }}
            </th>
            <th nzColumnKey="NumberParticipants">
                {{ 'activity.fields.participants' | translate }}
            </th>
            <th nzColumnKey="NumberWaitlist">
                <i class="fa-solid fa-hourglass"></i>
            </th>
            <th nzColumnKey="NumberRepresentatives">
                {{ 'activity.fields.representative' | translate }}
            </th>

            <th
                nzWidth="120px"
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Activity,
                        claimValues: [ClaimValues.Read, ClaimValues.Delete]
                    }
                ]"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of overviewTable.data" cdkDrag [cdkDragData]="row" [cdkDragDisabled]="!draggingEnabled">
            <td
                nzAlign="center"
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Blog,
                        claimValues: [ClaimValues.Update]
                    }
                ]">
                <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                <label
                    nz-checkbox
                    [nzChecked]="row.online"
                    (nzCheckedChange)="onChangeShowOnline(row.id, !row.online)"></label>
            </td>
            <td>{{ row.activityTypeName | truncateText }}</td>
            <td>{{ row.name | truncateText }}</td>
            <td>{{ row.code | truncateText }}</td>
            <td>{{ row.trainers | truncateText }}</td>
            <td>
                <div nz-row>
                    <div nz-col>
                        <a *ngIf="row.firstAttachment !== null" href="{{ row.firstAttachment.url }}">
                            <i class="fa-regular fa-file"></i>
                        </a>
                    </div>
                    <div nz-col>
                        <a *ngIf="row.secondAttachment !== null" href="{{ row.secondAttachment.url }}">
                            <i class="fa-regular fa-file"></i>
                        </a>
                    </div>
                </div>
            </td>
            <td>{{ row.location | truncateText }}</td>
            <td>{{ row.startDate | date }}</td>
            <td>{{ row.endDate | date }}</td>
            <td>{{ row.visibleFromDate | date }}</td>
            <td>{{ row.visibleUntilDate | date }}</td>
            <td>{{ row.numberRepresentatives }}</td>
            <td>{{ row.numberWaitlist }}</td>
            <td>{{ row.numberRepresentatives }}</td>

            <td
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Activity,
                        claimValues: [ClaimValues.Read, ClaimValues.Delete]
                    }
                ]">
                <div class="table-actions">
                    <a
                        claimDirective
                        [claims]="[
                            {
                                claimType: ClaimTypes.Activity,
                                claimValues: [ClaimValues.Read]
                            }
                        ]"
                        [routerLink]="['/activiteiten/detail', row.id]"
                        nz-button
                        nzType="text"
                        nzShape="circle">
                        <i class="fa-regular fa-magnifying-glass"></i>
                    </a>
                </div>
            </td>
        </tr>
    </tbody>
</nz-table>

<ng-template #templateIconDelete>
    <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
