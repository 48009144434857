import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { ActivityAssetModel } from '@app/modules/activity-download/models/activity-asset.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityAssetService {
    private readonly endpoints = {
        get: 'ActivityAsset/:id',
        getDefault: 'ActivityAsset/GetDefault',
    };

    constructor(private readonly httpService: HttpService) {}

    get(id: number): Observable<ActivityAssetModel[]> {
        return this.httpService.get<ActivityAssetModel[]>(this.endpoints.get.replace(':id', id.toString()));
    }

    getDefault(): Observable<ActivityAssetModel[]> {
        return this.httpService.get<ActivityAssetModel[]>(this.endpoints.getDefault);
    }
}
