import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as BackgroundApiActions from '@app/modules/background/actions/background-api.actions';
import * as Background from '@app/modules/background/reducers';
import * as NoteApiActions from '@app/modules/note/actions/note-api.actions';
import * as CertificateApiActions from '@app/modules/certificate/actions/certificate-api.actions';
import { NoteType } from '@app/modules/note/enum/note-type.enum';
import * as Note from '@app/modules/note/reducers';
import * as RelationActivitiesApiActions from '@app/modules/relation-activity/actions/relation-activity-api.actions';
import * as RelationActivity from '@app/modules/relation-activity/reducers';
import * as RelationApiActions from '@app/modules/relation/actions/relation-api.actions';
import * as Relation from '@app/modules/relation/reducers';
import * as Certificate from '@app/modules/certificate/reducers';
import { ActivityAvailableParticipantsEnum } from '@app/shared/enums/activity-available-participants';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-relation-view',
    templateUrl: './relation-view.component.html',
    styleUrls: ['./relation-view.component.less'],
})
export class RelationViewComponent {
    loading$ = this.store.select(Relation.selectViewLoading);
    errors$ = this.store.select(Relation.selectViewErrors);
    relation$ = this.store.select(Relation.selectViewRelation);

    notes$ = this.store.select(Note.selectNoteList);
    note$ = this.store.select(Note.selectNote);

    noteErrors$ = this.store.select(Note.selectErrors);
    noteLoading$ = this.store.select(Note.selectLoading);
    relationCatchupActivities$ = this.store.select(RelationActivity.selectGetCatchupParticipants);
    relationNonCatchupActivities$ = this.store.select(RelationActivity.selectGetNonCatchupParticipants);
    backgroundList$ = this.store.select(Background.selectBackgroundList);
    activityList$ = this.store.select(Relation.selectActivityList);
    activityRelation$ = this.store.select(RelationActivity.selectRelationActivity);
    certificates$ = this.store.select(Certificate.selectCertificateOverview);

    relationActivityCreateError$ = this.store.select(RelationActivity.selectRelationActivityCreateError);
    relationActivityCreateLoading$ = this.store.select(RelationActivity.selectRelationActivityCreateLoading);

    relationId: number | null = null;
    relationType: ActivityAvailableParticipantsEnum | null = null;
    relationTypeEnum = ActivityAvailableParticipantsEnum;

    constructor(private readonly store: Store) {
        this.relation$
            .pipe(takeUntilDestroyed())
            .subscribe((relation) => ((this.relationId = relation.id), (this.relationType = relation.relationType)));
        this.store.dispatch(NoteApiActions.SetNoteType({ noteType: NoteType.Relation, noteTypeId: this.relationId }));
        this.store.dispatch(NoteApiActions.GetOverview());
        this.store.dispatch(BackgroundApiActions.GetBackgroundList());
        this.getActivities();
        this.getCertificates();
    }

    delete() {
        this.store.dispatch(RelationApiActions.Delete());
    }

    resendWelcomeMail() {
        this.store.dispatch(RelationApiActions.ResendWelcomeMail());
    }

    loginAsRelation() {
        this.store.dispatch(RelationApiActions.LoginAsRelation());
    }

    resetCreateModal() {
        this.store.dispatch(RelationActivitiesApiActions.ResetCreate());
    }

    createNote(event: any) {
        this.store.dispatch(
            NoteApiActions.Create({ ...event, noteTypeId: this.relationId, noteType: NoteType.Relation }),
        );
    }

    editNote(event: any) {
        this.store.dispatch(
            NoteApiActions.Update({
                data: {
                    ...event,
                    noteMessageEdit: event.noteMessageEdit,
                    noteTypeId: this.relationId,
                    noteType: NoteType.ActivityWaitlist,
                },
            }),
        );
    }

    setNoteImportant(event: any) {
        this.store.dispatch(NoteApiActions.SetImportant({ noteId: event.noteId }));
    }

    deleteNote(event: any) {
        this.store.dispatch(NoteApiActions.Delete({ noteId: event.noteId }));
    }

    searchActivity(event: any) {
        this.store.dispatch(RelationApiActions.GetActivities(event));
    }

    createActivityRelation(event: any) {
        if (Object.keys(event).includes('id')) {
            this.store.dispatch(RelationActivitiesApiActions.Update(event));
        } else {
            this.store.dispatch(RelationActivitiesApiActions.Create(event));
        }
    }

    getActivityRelation(event: any) {
        this.store.dispatch(RelationActivitiesApiActions.GetActivityRelation({ id: event }));
    }

    getActivities() {
        this.store.dispatch(
            RelationActivitiesApiActions.FilterOverview({
                relationId: this.relationId,
                pagination: {
                    page: 1,
                    pageSize: -1,
                },
                sorting: {
                    propertyName: 'Role',
                    order: 'ASC',
                },
            }),
        );
    }

    getCertificates() {
        this.store.dispatch(
            CertificateApiActions.FilterOverview({
                pagination: {
                    page: 1,
                    pageSize: -1,
                },
                sorting: {
                    propertyName: 'GeneratedOn',
                    order: 'DESC',
                },
                relationId: this.relationId
            }),
        );
    }

    deleteCertificate(event: {id: number}) {
        this.store.dispatch(CertificateApiActions.Delete(event));
    }
}
